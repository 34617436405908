import { Grid } from "@material-ui/core";
import React from "react";
import {
  FilterButtonContained,
  FilterButtonOutlined,
  Input,
  InputNumber,
} from "../../../../components";
import { Texts } from "../../../../config";
import { Close, TransferRight } from "../../../../config/icons";
import Style from "../styles/EquationParametersStyle";
import ValueOfL from "./ValueOfL";

function SeparationValues({ isFetching, data }) {
  const texts = Texts["pt-BR"].setup.equations.equationParameters;
  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={1}>
          <Input
            name="processS"
            inputLabel={<Style.Label>{texts.process}</Style.Label>}
            readOnly
            defaultValue="Separação"
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            name="equationExib"
            inputLabel={<Style.Label>{texts.equationViewer}</Style.Label>}
            readOnly
            defaultValue="a + (b*x1) + (c*x2) + (d*x3) + (e*x4)"
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xaseparation"
            inputLabel={<ValueOfL letter="a" />}
            defaultValue={data.xaseparation}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xbseparation"
            inputLabel={<ValueOfL letter="b" />}
            defaultValue={data.xbseparation}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xcseparation"
            inputLabel={<ValueOfL letter="c" />}
            defaultValue={data.xcseparation}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xdseparation"
            inputLabel={<ValueOfL letter="d" />}
            defaultValue={data.xdseparation}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xeseparation"
            inputLabel={<ValueOfL letter="e" />}
            defaultValue={data.xeseparation}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <FilterButtonOutlined
                type="clearDefault"
                style={{ marginTop: 26 }}
                onClick={() => {}}
                startIcon={<Close />}
              >
                {texts.clear}
              </FilterButtonOutlined>
            </Grid>
            <Grid item xs={7}>
              <FilterButtonContained
                style={{ marginTop: 26 }}
                loading={isFetching}
                type="submit"
                name="button"
                disabled={isFetching}
                startIcon={<TransferRight />}
              >
                {texts.apply}
              </FilterButtonContained>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SeparationValues;
