import React, { useState } from "react";
import Styles from "../styles/Styles";

import { CustomText } from "../../../components";
import { Fonts, Spacing } from "../../../config";
import BeneficiosImg from "../../../assets/images/img-warehouse.jpg";
import BeneficiosImg1 from "../../../assets/images/img-warehouse_1.jpg";
import BeneficiosImg2 from "../../../assets/images/img-warehouse_2.jpg";
import BeneficiosImg3 from "../../../assets/images/img-warehouse_3.jpg";
import BeneficiosImg4 from "../../../assets/images/img-warehouse_4.jpg";
import { ReactComponent as Arrow } from "../../../assets/images/arrow-up.svg";
import fonts from "../../../config/fonts";
import colors from "../../../config/colors";
import { Button, IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
function ToolsInfo() {
  const [galleryId, setGalleryId] = useState(0);
  const boxValues = [
    { value: 25, up: true, title: "AUMENTO DE ENGAJAMENTO" },
    {
      value: 20,
      up: false,
      title: "REDUÇÃO DE DESPESAS COM FOLHA DE PAGAMENTO",
    },
    {
      value: 20,
      up: true,
      title: "AUMENTO DE PRODUTIVIDADE",
    },
  ];

  const gallery = [
    {
      img: BeneficiosImg,
      title: "Otimização da força de trabalho",
      subText:
        "Nosso sistema avançado de LMS permite otimizar o uso da sua força de trabalho, garantindo a alocação adequada de recursos e reduzindo a ociosidade.",
      subText2: "Isso resulta em maior produtividade e eficiência operacional.",
    },
    {
      img: BeneficiosImg1,
      title: "Rastreamento e análise de desempenho",
      subText:
        "Com nossa solução, você terá acesso a métricas e indicadores-chave de desempenho em tempo real.",
      subText2:
        "Acompanhe o desempenho dos colaboradores, identifique áreas de melhoria e tome decisões embasadas para aumentar a produtividade.",
    },
    {
      img: BeneficiosImg2,
      title: "Redução de custos operacionais",
      subText:
        "Nossa plataforma de LMS oferece recursos que ajudam a identificar e eliminar gargalos, reduzindo custos operacionais desnecessários.",
      subText2:
        "Ao otimizar processos e recursos, sua empresa poderá alcançar economias significativas.",
    },
    {
      img: BeneficiosImg3,
      title: "Planejamento e escalabilidade",
      subText:
        "Com nossa solução de LMS, você poderá realizar o planejamento estratégico da sua operação logística, considerando demandas sazonais, picos de trabalho e outros fatores.",
      subText2:
        "Além disso, nossa plataforma é altamente escalável, acompanhando o crescimento do seu negócio.",
    },
    {
      img: BeneficiosImg4,
      title: "Melhoria do engajamento dos colaboradores",
      subText:
        "Através de recursos como acompanhamento de metas, feedback contínuo e reconhecimento de desempenho, nossa solução de LMS ajuda a engajar e motivar seus colaboradores.",
      subText2: "Isso resulta em equipes mais comprometidas e produtivas.",
    },
  ];

  const cardChanger = (direction) => {
    let page = galleryId;
    if (!direction) {
      page = (page + 1) % gallery.length;
    } else {
      page = (page - 1 + gallery.length) % gallery.length;
    }

    setGalleryId(page);
  };
  return (
    <>
      <Styles.ToolContainer
        id="triangle-container"
        style={{
          overflow: "hidden",
          position: "relative",
          alignItems: "center",
        }}
      >
        <Styles.TriangleRight></Styles.TriangleRight>
        <Styles.ToolInfo>
          <CustomText
            style={{ textAlign: "center", paddingBottom: Spacing(2.5) }}
            fontFamily={Fonts.semibold}
            fontSize={Spacing(5.25)}
            color={"#0D518E"}
          >
            Benefícios
          </CustomText>
          <CustomText
            style={{ textAlign: "justify" }}
            fontFamily={Fonts.roman}
            fontSize={Spacing(3)}
            color="#1E1D2B"
          >
            Nossa solução de Labor Management System (LMS) oferece uma série de
            benefícios e soluções personalizadas para impulsionar a eficiência e
            o desempenho da sua operação logística
          </CustomText>
          <Styles.OperationStatus>
            {boxValues.map((item) => (
              <Styles.StatusValues>
                <Styles.StatusValuesBox>
                  <Arrow
                    style={{
                      transform: !item?.up && "scaleY(-1)",
                      maxHeight: Spacing(5.25),
                      width: "auto",
                      color: "#1E1D2B",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomText
                      style={{
                        paddingBottom: Spacing(1),

                        padding: 0,
                        margin: 0,
                        borderBottom: "2px solid #0D518E",
                      }}
                      fontSize={Spacing(5.25)}
                      fontFamily={fonts.semibold}
                      color="#0D518E"
                    >
                      {item?.value}
                    </CustomText>{" "}
                    <CustomText
                      fontSize={Spacing(5.25)}
                      fontFamily={fonts.semibold}
                      color="#0D518E"
                    >
                      %
                    </CustomText>
                  </div>
                </Styles.StatusValuesBox>
                <CustomText
                  fontSize={Spacing(3)}
                  fontFamily={fonts.semibold}
                  style={{ textAlign: "center" }}
                  color="#1E1D2B"
                >
                  {item?.title}
                </CustomText>
              </Styles.StatusValues>
            ))}
          </Styles.OperationStatus>
        </Styles.ToolInfo>
        <Styles.BenefíciosContent>
          <Styles.BenefíciosContentImg>
            <Styles.BenefíciosImg id="degre-img" src={gallery[galleryId].img} />
            <Styles.BenefíciosImgDegrade></Styles.BenefíciosImgDegrade>
            <IconButton
              onClick={() => cardChanger(true)}
              style={{
                backgroundColor: "#ffffff20",
                position: "absolute",
                top: "calc(50% - 24px)",
                left: 6,
                boxShadow: "0px 0px 20px #00000010",
              }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={() => cardChanger(false)}
              style={{
                backgroundColor: "#ffffff20",
                position: "absolute",
                top: "calc(50% - 24px)",
                right: 6,
                boxShadow: "0px 0px 20px #00000010",
              }}
            >
              <ChevronRight />
            </IconButton>
          </Styles.BenefíciosContentImg>

          <Styles.BenefíciosContentText>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: Spacing(1.25),
                  paddingBottom: Spacing(1),
                }}
              >
                {gallery.map((item, index) => (
                  <Button
                    style={{
                      backgroundColor:
                        index === galleryId ? "#5AB6E5" : "#656775",
                      padding: 0,
                      margin: 0,
                      borderRadius: "90px",
                      width: "30px",
                      height: "8px",
                      minWidth: 0,
                    }}
                    onClick={() => {
                      setGalleryId(index);
                      console.log(index);
                    }}
                  ></Button>
                ))}
              </div>
              <CustomText
                fontSize={Spacing(3)}
                fontFamily={fonts.bold}
                color={colors.darkBlue2}
              >
                {gallery[galleryId].title}
              </CustomText>
            </div>

            <div>
              <Styles.SubText
                fontSize={Spacing(3)}
                fontFamily={fonts.roman}
                color={"#1E1D2B"}
              >
                {gallery[galleryId].subText}
              </Styles.SubText>
              <Styles.SubText
                fontSize={Spacing(3)}
                fontFamily={fonts.roman}
                color={"#1E1D2B"}
              >
                {gallery[galleryId].subText2}
              </Styles.SubText>
            </div>
          </Styles.BenefíciosContentText>
        </Styles.BenefíciosContent>
      </Styles.ToolContainer>
    </>
  );
}

export default ToolsInfo;
