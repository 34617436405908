import { Grid } from "@material-ui/core";
import React from "react";
import { Input, InputNumber } from "../../../../components";
import { Texts } from "../../../../config";
import Style from "../styles/EquationParametersStyle";
import ValueOfL from "./ValueOfL";

function CheckoutValues({ data }) {
  const texts = Texts["pt-BR"].setup.equations.equationParameters;

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid item xs={1}>
          <Input
            name="processS"
            inputLabel={<Style.Label>{texts.process}</Style.Label>}
            readOnly
            defaultValue="Checkout"
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={3}>
          <Input
            name="equationExib"
            inputLabel={<Style.Label>{texts.equationViewer}</Style.Label>}
            readOnly
            defaultValue="a + (b*x1) + (c*x2) + (d*x3) + (e*x4) + (f*x5) + (g*x6) + (h*x8)"
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xacheckout"
            inputLabel={<ValueOfL letter="a" />}
            defaultValue={data.xacheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xbcheckout"
            inputLabel={<ValueOfL letter="b" />}
            defaultValue={data.xbcheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xccheckout"
            inputLabel={<ValueOfL letter="c" />}
            defaultValue={data.xccheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xdcheckout"
            inputLabel={<ValueOfL letter="d" />}
            defaultValue={data.xdcheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xecheckout"
            inputLabel={<ValueOfL letter="e" />}
            defaultValue={data.xecheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xfcheckout"
            inputLabel={<ValueOfL letter="f" />}
            defaultValue={data.xfcheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xgcheckout"
            inputLabel={<ValueOfL letter="g" />}
            defaultValue={data.xgcheckout}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={1}>
          <InputNumber
            name="xhcheckout"
            inputLabel={<ValueOfL letter="h" />}
            defaultValue={data.xhcheckout}
            alternativeColors={2}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default CheckoutValues;
