import React from "react";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import OrderingGrid from "../components/OrderingGrid";
import { IntegratedComponentHolder } from "../../../components";
import { Texts } from "../../../config";
import Styles from "../styles/OrderingGridStyle";
import { ExitToApp } from "@material-ui/icons";
import { BlockTitle } from "../../../pages/styles/defaultStyles";
import { ToCSV } from "../../../lib";
import { Switch } from "@material-ui/core";

function HistoricProductivity({
  orderingProductivityData,
  isFetching,
  globalProductivityRequest,
}) {
  const [orderingUp, setOrderingUp] = React.useState(false);

  const sortList = React.useCallback(
    (listToSort, ignoreStateOrder) => {
      return listToSort.sort((a, b) => {
        if (a.productivity > b.productivity) {
          return orderingUp && !ignoreStateOrder ? 1 : -1;
        } else if (a.productivity < b.productivity) {
          return orderingUp && !ignoreStateOrder ? -1 : 1;
        }
        return 0;
      });
    },
    [orderingUp]
  );

  const list = React.useMemo(() => {
    if (isFetching) {
      return null;
    } else if (orderingProductivityData?.length) {
      const result = sortList(
        orderingProductivityData.filter((item) => item.productivity > 0)
      );
      return result.length ? result : [];
    }
    return null;
  }, [sortList, orderingProductivityData, isFetching]);

  const texts = Texts["pt-BR"].charts.productivityOrdering;

  return (
    <>
      <Styles.RowTitle
        $onlyTitle={
          isFetching ||
          !orderingProductivityData ||
          !orderingProductivityData.length
        }
      >
        <Styles.TitleContainer>
          <BlockTitle>{texts.name}</BlockTitle>
        </Styles.TitleContainer>
        {!isFetching &&
          orderingProductivityData &&
          orderingProductivityData.length ? (
          <Styles.ButtonsContainer>
            <Styles.OrderingButtonContainer>
              <Styles.OrderingButton onClick={() => setOrderingUp(!orderingUp)}>
                <Switch checked={orderingUp} />{" "}
                {orderingUp ? texts.orderingButtonUp : texts.orderingButtonDown}
              </Styles.OrderingButton>
            </Styles.OrderingButtonContainer>
            <Styles.ExportButtonContainer>
              <Styles.ExportButton
                startIcon={<ExitToApp />}
                // onClick={() => ToCSV.table("productivityTable", texts.csvName)}
                onClick={() =>
                  ToCSV.data(
                    sortList(orderingProductivityData, true),
                    texts.tableCSV,
                    [
                      "name",
                      "registrationId",
                      "process",
                      "productivityTax",
                      "currentRank",
                      "lastMonthRank",
                    ],
                    texts.csvName
                  )
                }
              >
                {texts.exportButton}
              </Styles.ExportButton>
            </Styles.ExportButtonContainer>
          </Styles.ButtonsContainer>
        ) : null}
      </Styles.RowTitle>
      <IntegratedComponentHolder
        showReload={!list && !isFetching}
        reloadMessage={texts.notFound.message}
        reloadCallback={globalProductivityRequest}
        reloadLabel={texts.notFound.buttonLabel}
        showEmpty={!list?.length && !isFetching}
        emptyMessage={texts.emptyList}
      >
        <OrderingGrid data={list} loading={isFetching} />
      </IntegratedComponentHolder>
    </>
  );
}

function mapStateToProps(state) {
  const { orderingProductivityData, isFetching } = state.productivity;
  return {
    orderingProductivityData,
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { globalProductivityRequest } = Creators;
  return {
    globalProductivityRequest: function () {
      return dispatch(globalProductivityRequest());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricProductivity);
