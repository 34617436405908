import React from "react";
import { InputDateMMyyyy } from "../../../components";
import { Texts } from "../../../config";
import { Validations } from "../../../lib";
import Styles from "../styles/FilterStyles";

function getDefaultDate(addDate = 0, addMonth = 0) {
  const date = new Date();
  date.setDate(date.getDate() + addDate);
  date.setMonth(date.getMonth() + addMonth);
  return date;
}
function VisionFilterContainer() {
  const texts = Texts["pt-BR"].header.productivityFilters.period;
  return (
    <Styles.PeriodContainer>
      <Styles.PeriodGroupContainer $withMargin>
        <Styles.PeriodText>{texts.fromLabel}</Styles.PeriodText>
        <InputDateMMyyyy
          disableError
          small
          alternativeColors
          defaultValue={getDefaultDate(0, -1)}
          name="startPeriod"
          validation={(value) => Validations.isDATE(value)}
        />
      </Styles.PeriodGroupContainer>

      <Styles.PeriodGroupContainer>
        <Styles.PeriodText>{texts.toLabel}</Styles.PeriodText>
        <InputDateMMyyyy
          disableError
          small
          alternativeColors
          defaultValue={getDefaultDate(0, -1)}
          name="endPeriod"
          validation={(value) => Validations.isDATE(value)}
        />
      </Styles.PeriodGroupContainer>
    </Styles.PeriodContainer>
  );
}

export default VisionFilterContainer;
