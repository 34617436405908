export default function getUserInfos(response) {
  return {
    role: "Admin",
    avatarName: "LA",
    userId: 1,
    email: "labor@laboranalytics.com.br",
    isAdmin: true,
    enable: true,
    name: "Labor Analytics",
  };
}
