import React from "react";
import { Homepage } from "../features";

function HomePage() {
  return (
    <>
      <Homepage />
    </>
  );
}

export default HomePage;
