import React from "react";
import { HeaderStyles as Styles } from "../styles";
import {
  DrawerMenu,
  Filters,
  LeftFunctionalitiesButtons,
  NavigationButtons,
  UserInfos,
} from "../presentations";
import { DefaultLogo } from "../../../components";

function Header() {
  const [visible, setVisible] = React.useState(false);

  return (
    <Styles.Container>
      <Styles.Content $maxWidth="xxl">
        <Styles.FirstContentGroup>
          <DrawerMenu />
          <DefaultLogo />
        </Styles.FirstContentGroup>
        <NavigationButtons
          showFilters={() => {
            if (!visible) {
              setVisible(true);
            }
          }}
        />

        <Styles.ThirdContentGroup>
          <UserInfos />
          <LeftFunctionalitiesButtons />
        </Styles.ThirdContentGroup>
      </Styles.Content>
      <Filters
        visible={visible}
        hideFilters={() => {
          if (visible) {
            setVisible(false);
          }
        }}
      />
    </Styles.Container>
  );
}

export default React.memo(Header);
