import React from "react";
import { connect } from "react-redux";
import { PagesConfig } from "../../../config";
import { navigate } from "../../../navigation/navigate";
import { NavigationButtonsStyles as Styles } from "../styles";
import { FiltersButton } from "../components";
import { Alerts } from "../../../lib";

function NavigationButtons({ pages, withFilter, pathname, showFilters }) {
  const goto = (item) => {
    if (item.working === false) {
      Alerts.alertNotImplemented();
    } else {
      navigate(item.path);
    }
  };

  return pages ? (
    <Styles.Container>
      {withFilter ? (
        <>
          <Styles.ButtonContainer>
            <FiltersButton
              onClick={(event) => {
                event.stopPropagation();
                showFilters();
              }}
            />
          </Styles.ButtonContainer>
          <Styles.NavigationContainer>
            {pages.map((item, index) => {
              if (item.showOnHeader === true) {
                const Button =
                  item.path === pathname
                    ? Styles.ButtonContained
                    : Styles.ButtonOutlined;
                return (
                  <Styles.ButtonContainer key={index}>
                    <Button
                      disabled={!item.working}
                      onClick={() => goto(item)}
                      startIcon={<item.icon />}
                    >
                      {item.title}
                    </Button>
                  </Styles.ButtonContainer>
                );
              } else {
                return null;
              }
            })}
          </Styles.NavigationContainer>
        </>
      ) : null}
    </Styles.Container>
  ) : null;
}

function getNavigationPagesAndCheckFilter(item, pathname) {
  let j = 0;
  const jMax = item.pages.length;
  for (; j < jMax; j++) {
    const page = item.pages[j];
    if (pathname === page.path) {
      return { pages: item.pages, withFilter: page.withFilter };
    }
  }
  return { pages: null, withFilter: false };
}

function getNavigationGroup(pathname) {
  const items = PagesConfig.logged;
  let i = 0;
  const iMax = items.length;
  let result = null;
  for (; i < iMax; i++) {
    const item = items[i];
    if (item.groupTitle && item.pages) {
      result = getNavigationPagesAndCheckFilter(item, pathname);
    }
    if (result?.pages) {
      break;
    }
  }
  return result;
}

function mapStateToProps({ router }) {
  const { pages, withFilter } = getNavigationGroup(router.location.pathname);
  return {
    pages,
    withFilter,
    pathname: router.location.pathname,
  };
}

export default connect(mapStateToProps)(React.memo(NavigationButtons));
