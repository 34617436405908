import React, { useEffect, useRef } from "react";

import { FormControl, Input, FormHelperText } from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { formConnector } from "../../FormConfig";
import Text from "../strings/Text";
import { FileEndAdornment, IconEndAdornment } from "./inputsComponents";
import { Fonts } from "../../config";

const Label = styled(Text)(
  ({ theme, $withValue, $withError, $alternativeColors }) => {
    const { palette: colors, typography } = theme;

    return {
      color: $withValue
        ? $withError
          ? colors.error.main
          : $alternativeColors === 3
          ? "#FAFBFC"
          : "#0D518E"
        : typography.body2.color,
      transition: ".2s",

      fontFamily: $alternativeColors === 3 ? Fonts.roman : Fonts.semibold,
      fontSize: Fonts.sizes.small,
      textTransform: "uppercase",
    };
  }
);

const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativeColors,
    $multiline,
    $name,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;

    return {
      padding: $multiline
        ? spacing(2) + "px !important"
        : $small
        ? spacing(0, 1)
        : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor:
        $alternativeColors === 2
          ? colors.lightBlueInput
          : $alternativeColors === 3
          ? "#ffffff00"
          : $alternativeColors
          ? colors.text.secondary
          : "#E8F4FF",
      borderRadius: 5,
      border: $alternativeColors === 3 ? "1px solid #fff" : null,
      marginTop: spacing(1.125),
      marginBottom: $alternativeColors === 3 ? spacing(1) : null,
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.error.main}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        color: $alternativeColors
          ? colors.text.primary
          : colors.background.default,

        "&::placeholder": {
          opacity: 0.8,
        },
      },
      ...$inputStyle,
    };
  }
);

function InputComponent(props) {
  const {
    value,
    error,
    handleKeyPress,
    handleKeyDown,
    onBlur,
    setConfigs,
    ref,
    formHandler,
    fileInputRef,
    usedProps,
  } = formConnector.useStringValue(props);

  const {
    inputLabel,
    icon,
    theme,
    required,
    color,
    disableError,
    hideVisualError,
    margin = "none",
    variant = "outlined",
    inputStyle,
    type,
    downloadButtonLabel,
    uploadButtonLabel,
    withDownload,
    hideButtonLabel,
    readOnly,
    downloadHref,
    openOnNewTab,
    autoComplete = false,
    accept,
    small,
    alternativeColors,
    nameId,
    ...rest
  } = usedProps;
  const labelRef = useRef(null);

  useEffect(() => {
    // Aqui obtemos a altura do componente após ele ser renderizado
    console.log(labelRef?.current);
    if (labelRef.current) {
      console.log(labelRef?.current);
      const altura = labelRef.current.clientHeight;
      console.log("Altura do componente:", altura);
    }
  }, [labelRef]);
  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <Label
        ref={labelRef}
        $withValue={Boolean(value) || value === 0 || usedProps.placeholder}
        $withError={Boolean(error)}
        $alternativeColors={alternativeColors}
      >
        {inputLabel}
      </Label>
      <StyledInput
        $name={nameId}
        {...rest}
        $alternativeColors={alternativeColors}
        $small={small}
        $multiline={rest?.multiline}
        autoComplete={autoComplete ? autoComplete : null}
        readOnly={readOnly}
        disabled={props.disabled || type === "file"}
        $error={hideVisualError ? null : Boolean(error)}
        onClick={type === "file" ? () => fileInputRef.current.click() : null}
        disableUnderline
        endAdornment={
          type === "file" ? (
            <FileEndAdornment
              fileInputRef={fileInputRef}
              downloadButtonLabel={downloadButtonLabel}
              uploadButtonLabel={uploadButtonLabel}
              downloadHref={downloadHref}
              openOnNewTab={openOnNewTab}
              hideButtonLabel={hideButtonLabel}
            />
          ) : icon && icon.Component ? (
            <IconEndAdornment formHandler={formHandler} icon={icon} />
          ) : null
        }
        type={type === "file" ? null : type}
        value={type === "file" ? (value ? value.name : "") : value}
        required={Boolean(required)}
        variant={variant}
        color={alternativeColors === 3 ? "#fafbfce0" : color}
        label={value ? inputLabel : null}
        fullWidth
        margin={margin}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        inputRef={ref}
        onChange={
          type !== "file" ? (event) => setConfigs(event.target.value) : null
        }
        onBlur={(event) => {
          setTimeout(() => {
            onBlur(event);
            formHandler.testInputError(props.name);
          }, 10);
        }}
      />
      {disableError ? null : (
        <FormHelperText error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(InputComponent);
