import {
  Equation,
  reducers as equationsReducers,
  sagas as equationsSagas,
} from "./equations";

const Setup = {
  Equation,
};
const reducers = {
  setupEquations: equationsReducers,
};
const sagas = [...equationsSagas];
export { reducers, sagas, Setup };
