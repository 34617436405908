import React from "react";
import { connect } from "react-redux";
import { Texts } from "../../../../config";
import { FormHolder } from "../../../../FormConfig";
import CheckoutValues from "../components/CheckoutValues";
import SeparationValues from "../components/SeparationValues";
import { Creators } from "../reduxSagas";
import Style from "../styles/EquationParametersStyle";

function EquationParameters({
  equationsHistoryData,
  isFetching,
  EquationsRequestLoad,
  EquationsRequestSave,
}) {
  const texts = Texts["pt-BR"].setup.equations.equationParameters;
  const mount = React.useCallback(() => {
    EquationsRequestLoad();
  }, [EquationsRequestLoad]);

  React.useEffect(mount, [mount]);

  function sendParameters(data) {
    EquationsRequestSave(data);
  }

  return (
    <>
      <div>{texts.title}</div>
      <Style.Content>
        {equationsHistoryData ? (
          <FormHolder onSubmit={sendParameters}>
            <Style.InputContainer>
              <CheckoutValues
                data={equationsHistoryData[0]}
                isFetching={isFetching}
              />
            </Style.InputContainer>
            <Style.InputContainer>
              <SeparationValues
                data={equationsHistoryData[0]}
                isFetching={isFetching}
              />
            </Style.InputContainer>
          </FormHolder>
        ) : null}
      </Style.Content>
    </>
  );
}

function mapStateToProps(state) {
  const { equationsHistoryData, isFetching } = state.setupEquations;
  return {
    equationsHistoryData,
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { EquationsRequestLoad, EquationsRequestSave } = Creators;
  return {
    EquationsRequestLoad: function () {
      return dispatch(EquationsRequestLoad());
    },
    EquationsRequestSave: function (data) {
      return dispatch(EquationsRequestSave(data));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquationParameters);
