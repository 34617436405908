import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import ResponsiveComponent from "../../../components/others/ResponsiveComponent";
import { Spacing } from "../../../config";
import Styles from "../styles/StyleHeader";

class HomePage extends ResponsiveComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  render() {
    const pages = [
      { title: "Benefícios", component: "beneficios" },
      { title: "Funcionalidades", component: "funcionalidades" },
      { title: "Contato", component: "contato" },
    ];

    return (
      <div id="navHeader">
        <Styles.Container state={this.state}>
          <Styles.Logo state={this.state} />
          <Styles.MobileMenu>
            <Styles.PageButton
              style={{
                width: "100%",
                justifyContent: "space-between",
                marginTop: Spacing(2),
              }}
              state={this.state}
              onClick={() => this.setState({ showMenu: !this.state.showMenu })}
            >
              Menu {this.state.showMenu ? <ExpandMore /> : <ExpandLess />}
            </Styles.PageButton>
            <Styles.DropDownMenu state={this.state} $open={this.state.showMenu}>
              {pages.map((item) => (
                <Styles.PageButton
                  onClick={() => {
                    item.component
                      ? document
                          .getElementById(item.component)
                          ?.scrollIntoView()
                      : console.log(item.path);
                  }}
                  state={this.state}
                >
                  {item.title}
                </Styles.PageButton>
              ))}
            </Styles.DropDownMenu>
          </Styles.MobileMenu>

          <Styles.MenuDesk>
            {pages.map((item) => (
              <Styles.PageButton
                onClick={() => {
                  item.component
                    ? document.getElementById(item.component)?.scrollIntoView()
                    : console.log(item.path);
                }}
                state={this.state}
              >
                {item.title}
              </Styles.PageButton>
            ))}
          </Styles.MenuDesk>
        </Styles.Container>
      </div>
    );
  }
}

export default HomePage;
