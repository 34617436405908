import models from ".";

export default function getCapacityProcessCharts(response) {
  const checkout = models.getCapacityCheckoutProcessCharts(
    response.data.checkout.data
  );
  const load = models.getCapacityLoadProcessCharts(
    response.data.conferente.data
  );
  const separation = models.getCapacitySeparationProcessCharts(
    response.data.separador.data
  );

  return {
    ...response,
    data: {
      checkout: checkout.data,
      load: load.data,
      separation: separation.data,
    },
  };
}
