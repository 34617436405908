import React from "react";
import ResponsiveComponent from "../../../components/others/ResponsiveComponent";
import { Texts } from "../../../config";
import Styles from "./Styles/HomeStyle";
import Trofeu from "../../../assets/images/img-trofeu-bbm.png";
class HomePage extends ResponsiveComponent {
  render() {
    const texts = Texts["pt-BR"].home.home;
    return (
      <Styles.Container state={this.state}>
        <Styles.Title state={this.state}>{texts.title}</Styles.Title>
        <Styles.SubTitle state={this.state}>{texts.text}</Styles.SubTitle>
        <Styles.RewardContainer>
          <img
            alt="Prêmio-BBM-2022"
            style={{ width: "120px", marginTop: "-33px" }}
            src={Trofeu}
          />
          <div>
            <Styles.SubText>
              <Styles.SubTitle $alternative state={this.state}>
                {texts.subtext}
              </Styles.SubTitle>
            </Styles.SubText>
            <Styles.ButtonDiv>
              <Styles.ButtonDemo
                onClick={() =>
                  document.getElementById("beneficios")?.scrollIntoView()
                }
              >
                {texts.buttonText}
              </Styles.ButtonDemo>
            </Styles.ButtonDiv>
          </div>
        </Styles.RewardContainer>
      </Styles.Container>
    );
  }
}

export default HomePage;
