import React from "react";
import { Texts } from "../config";
import { Productivity } from "../features";
import { ProductivityStyles as Styles } from "./styles";
import { Content, BlockTitle } from "./styles/defaultStyles";

function ProductivityPage() {
  const texts = Texts["pt-BR"].charts;
  return (
    <Styles.Container>
      <Content>
        <BlockTitle>{texts.globalProductivity.name}</BlockTitle>
        <Productivity.GlobalProductivity />
      </Content>
      <Content>
        <BlockTitle>{texts.historicProductivity.name}</BlockTitle>
        <Productivity.HistoricProductivity />
      </Content>
      <Styles.Content>
        <BlockTitle>{texts.productivityProcess.name}</BlockTitle>
        <Productivity.Process />
      </Styles.Content>
      <Styles.Content>
        <Productivity.OrderingProductivity />
      </Styles.Content>
    </Styles.Container>
  );
}

export default ProductivityPage;
