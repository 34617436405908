const light =
  '"Montserrat-Light", "neue light", "Helvetica", "Arial", sans-serif';
const bold = '"Montserrat-Bold","neue bold", "Helvetica", "Arial", sans-serif';
const medium =
  '"Montserrat-Medium","neue medium", "Helvetica", "Arial", sans-serif';
const roman =
  '"Montserrat-Regular","neue roman", "Helvetica", "Arial", sans-serif';
const italic = '"neue italic", "Helvetica", "Arial", sans-serif';
const black =
  '"Montserrat-ExtraBold","neue black", "Helvetica", "Arial", sans-serif';
const semibold = '"Montserrat-SemiBold","Helvetica", "Arial", sans-serif';
const sizes = {
  /**
   * "Fonte 12"
   */
  smaller: "0.75rem",
  /**
   * "Fonte 14"
   */
  small: "0.875rem",
  /**
   * "Fonte 16"
   */
  regular: "1rem",
  /**
   * "Fonte 18"
   */
  medium: "1.125rem",
  /**
   * "Fonte 22"
   */
  large: "1.375rem",
  /**
   * "Fonte 32"
   */
  mediumlarge: "1.75rem",
  /**
   * "Fonte 42"
   */
  extraLarge: "2.625rem",
  /**
   * "Fonte 64"
   */
  xxxL: "3.75rem",
};

const fonts = {
  bold,
  light,
  sizes,
  medium,
  roman,
  italic,
  black,
  semibold,
};

export default fonts;
