import styled from "styled-components";
import { Logos, MediaQueries, Spacing } from "../../../config";
import { FontStyles } from "../../../components";

const Container = styled.div((props) => ({
  display: "flex",
  width: "100%",

  backgroundColor: "transparent",
  zIndex: 9999999999,
  [MediaQueries.smDown]: { flexDirection: "column" },
}));

const BlueContainer = styled.div((props) => ({
  display: "flex",
  width: "100%",

  paddingInline: props.state.mdUp ? Spacing(15) : Spacing(10),
  backgroundColor: "#6AB9FF",
}));
const BlueContainerText = styled.p((props) => ({
  margin: 0,
  padding: 0,
  ...FontStyles.bold14,
  color: "white",
  marginBlock: props.state.mdUp ? Spacing(1.25) : Spacing(0.6),
}));

const Logo = styled(Logos.LogoWhite)((props) => ({
  height: props.state.mdUp ? "47px" : "32px",
  marginRight: props.state.mdUp ? Spacing(10) : Spacing(2),
  opacity: 1,
  [MediaQueries.smDown]: {
    alignSelf: "center",
    margin: 0,
  },
}));

const Right = styled.div((props) => ({
  display: "flex",
  marginLeft: "auto",
}));
const Left = styled.div((props) => ({
  display: "flex",
  marginRight: "auto",
}));

const MenuItem = styled.div((props) => ({
  paddingLeft: Spacing(4),
  color: "#ffffff",
  display: "flex",
  flexDirection: "row",
  ...FontStyles.roman16,
}));

const Menu = styled.div((props) => ({
  display: "flex",
  width: "100%",
  paddingRight: Spacing(6),
}));

const PageButton = styled.a((props) => ({
  display: "flex",

  alignItems: "center",
  borderBottom: props.isSelected && "2px solid white",
  paddingInline: props.state.mdUp ? Spacing(2.5) : Spacing(1.75),
  paddingBlock: props.state.mdUp ? Spacing(3.25) : Spacing(2),
  cursor: "pointer",
  ...FontStyles.medium16,
  color: "white",
  ":hover": {
    backgroundColor: "#ffffff10",
  },
}));

const MenuDesk = styled.div((props) => ({
  display: "flex",
  [MediaQueries.smDown]: { display: "none" },
}));

const MobileMenu = styled.div((props) => ({
  display: "none",
  flexDirection: "column",
  [MediaQueries.smDown]: { display: "flex" },
}));

const DropDownMenu = styled.div((props) => ({
  display: props?.$open ? "flex" : "none",

  flexDirection: "column",
}));

const Styles = {
  Container,
  Right,
  Left,
  Logo,
  MenuItem,
  Menu,
  BlueContainer,
  BlueContainerText,
  PageButton,
  MenuDesk,
  MobileMenu,
  DropDownMenu,
};

export default Styles;
