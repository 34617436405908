import React from "react";
import { connect } from "react-redux";
import { Table } from "../../../../components";
import { Texts } from "../../../../config";
import { Creators } from "../reduxSagas";
import Style from "../styles/EquationParametersStyle";
import TwoLine from "../components/TwoLine";

function EquationTable({ equationsHistoryData, isFetching }) {
  const texts = Texts["pt-BR"].setup.equations.equationTable;

  return (
    <>
      <div>{texts.title}</div>
      {equationsHistoryData ? (
        <Style.Content>
          <Style.InputContainer>
            <Table
              id="equationTable"
              headers={texts.tableHeaders}
              data={equationsHistoryData}
              placeholderSize={15}
              columnSize={6}
              loading={isFetching}
              renderItemColumns={(item) => [
                <TwoLine v2={texts.separation} v1={texts.checkout} />,
                <TwoLine
                  v2={new Date(item.createDate).toLocaleTimeString()}
                  v1={new Date(item.createDate).toLocaleDateString()}
                />,
                <TwoLine v2={item.xaseparation} v1={item.xacheckout} />,
                <TwoLine v2={item.xbseparation} v1={item.xbcheckout} />,
                <TwoLine v2={item.xcseparation} v1={item.xccheckout} />,
                <TwoLine v2={item.xdseparation} v1={item.xdcheckout} />,
                <TwoLine v2={item.xeseparation} v1={item.xecheckout} />,
                <TwoLine v2={"-"} v1={item.xfcheckout} />,
                <TwoLine v2={"-"} v1={item.xgcheckout} />,
                <TwoLine v2={"-"} v1={item.xhcheckout} />,
              ]}
            />
          </Style.InputContainer>
        </Style.Content>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  const { equationsHistoryData, isFetching } = state.setupEquations;
  return {
    equationsHistoryData,
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { EquationsRequestLoad } = Creators;
  return {
    EquationsRequestLoad: function () {
      return dispatch(EquationsRequestLoad());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EquationTable);
