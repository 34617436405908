import React from "react";
import Styles from "../styles/Styles";

function HomePage() {
  return (
    <Styles.Container>
      <Styles.Background>
        <Styles.BackgroundImage />
      </Styles.Background>
      <Styles.Logo />
    </Styles.Container>
  );
}

export default HomePage;
