import React, { Component } from "react";

import { Filters, Validations } from "../../lib";

import Input from "./Input";

export default class InputCellphone extends Component {
  render() {
    return (
      <Input
        {...this.props}
        type="tel"
        mask={Filters.inputMaskCELWithDDD}
        validation={Validations.isPhoneWithDDD}
        maskToSubmit={Filters.clearStringOnlyNumbers}
        maxLength={16}
        autoCompleteType="tel"
      />
    );
  }
}
