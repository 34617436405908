const baseRequiredMessage = "Campo obrigatório.";
// const baseRequiredFieldsDescription = "* Preenchimento obrigatório";

const baseReload = {
  message: "Não há dados para exibição.",
  buttonLabel: "Recarregar",
};

const ptBr = {
  months: [
    { initials: "Jan", fullName: "Janeiro" },
    { initials: "Fev", fullName: "Fevereiro" },
    { initials: "Mar", fullName: "Março" },
    { initials: "Abr", fullName: "Abril" },
    { initials: "Mai", fullName: "Maio" },
    { initials: "Jun", fullName: "Junho" },
    { initials: "Jul", fullName: "Julho" },
    { initials: "Ago", fullName: "Agosto" },
    { initials: "Set", fullName: "Setembro" },
    { initials: "Out", fullName: "Outubro" },
    { initials: "Nov", fullName: "Novembro" },
    { initials: "Dez", fullName: "Dezembro" },
  ],
  version: "ProLOG ",
  modals: {
    success: {
      buttonLabel: "OK",
    },
    errors: {
      buttonLabel: "Ok",
    },
  },
  home: {
    home: {
      title: <>Aumente a performance <br></br> de sua cadeia logística</>,
      text: (
        <>
          Conheça a plataforma avançada de <strong>LMS</strong> que promove
          economia, engaja colaboradores e eleva o desempenho operacional
        </>
      ),
      subtext: (
        <>
          Projeto vencedor do <strong>PRÊMIO BBM 2022</strong> na categoria
          inovação
        </>
      ),
      buttonText: "SAIBA MAIS",
      language: {
        title: "Idioma",
        languages: [
          { label: "PT-BR", value: "pt-BR" },
          { label: "EN-US", value: "en-US" },
        ],
      },
      menu: { home: "Home", solutions: "Soluções", faq: "FAQ" },
    },
    freeDemo: {
      title: "Demonstração Gratuita",
      subtitle:
        "Para agendar, por favor, informe seus dados para contato abaixo:",
      name: "Nome",
      email: "Email",
      workersNumber: "Número de funcionários",
      phone: "Telefone",
      companyName: "Razão social",
      role: "Cargo",
      comment: "Comentários",
      commentPlaceHolder: "Comente aqui sua necessidade",
      send: "Enviar",
      back: "Voltar",
    },
  },
  pagesConfig: {
    setup: {
      title: "SETUP",
      pages: {
        upload: "Upload",
        dimensions: "Dimensões",
        process: "Processos",
        drivers: "Drivers",
        segment: "Segmentos",
        skus: "SKUs",
        operators: "Operadores",
        schedules: "Horários",
        layout: "Layout",
        alocations: "Alocações",
        costs: "Custos",
        equations: "Equação Planejada",
        integration: "Integrações",
        scenario: "Cenários",
      },
    },
    model: {
      title: "MODELO",
      pages: {
        upload: "Upload",
        builder: "Builder",
        process: "Processos",
        subProcess: "Subprocessos",
        actives: "Atividades",
        standardTimes: "Tempos padrões",
        processBlock: "Blocos de processo",
        conditional: "Condicionais",
        allocations: "Alocações",
      },
    },
    data: {
      title: "DADOS",
      pages: {
        upload: "Upload",
        extract: "Extract",
        transform: "Transform",
        load: "Load",
        allocations: "Alocações",
        functions: "Funções",
        conditional: "Condicionais",
        links: "Vínculos",
      },
    },
    process: { title: "PROCESSAR" },

    dashboard: {
      title: "DASHBOARD",
      pages: {
        productivity: "Produtividade",
        capacity: "Capacidade",
        cost: "Custo",
        opportunity: "Oportunidade",
      },
    },

    reports: { title: "RELATÓRIOS" },

    coaching: { title: "COACHING" },
  },

  login: {
    cdTitle: "Selecione a unidade",
    title: "LOGIN",
    remember: "Lembrar-me",
    forgotPass: "Esqueceu sua senha?",
    enter: "Entrar",
    continue: "Continuar",
    password: "Senha",
    selectCD: "Selecionar outra unidade",
    enterIn: (cd) => {
      return `Entrando na unidade: ${cd}`;
    },
    passwordMessage: baseRequiredMessage,
    user: "Usuário",
    userMessage: baseRequiredMessage,
  },
  forgotPass: {
    title: "ESQUECEU SUA SENHA?",
    subTitle:
      "Efetue o processo de recuperação de sua senha informando seu email",
    successResponse: "Senha alterada com sucesso.",
    resendCode: "Reenviar código",
    inputEmailLabel: "E-mail",
    inputEmailRequiredMessage: baseRequiredMessage,
    inputCodeLabel: "Código",
    inputCodeRequiredMessage: baseRequiredMessage,
    inputPasswordLabel: "Nova senha",
    inputPasswordRequiredMessage: baseRequiredMessage,
    inputConfirmPasswordLabel: "Confirme sua senha",
    inputConfirmPasswordRequiredMessage: baseRequiredMessage,
    submitEmailButton: "ENVIAR SOLICITAÇÃO",
    submitCodeButton: "ENVIAR CÓDIGO",
    submitPassButon: "TROCAR SENHA",
    goBack: "VOLTAR",
  },
  settings: {
    userSettings: {
      users: "Usuários",
      userRegister: {
        userRegister: "Cadastro de Usuário",
        fullName: "Nome Completo",
        fullNameMessage: baseRequiredMessage,
        email: "E-mail",
        emailMessage: baseRequiredMessage,
        password: "Senha",
        passwordMessage: baseRequiredMessage,
        passwordConfirm: "Confirmar Senha",
        passwordConfirmMessage: baseRequiredMessage,
        userAdmin: "Usuário Administrador?",
        register: "Cadastrar",
        successResponse: "Usuário cadastrado com sucesso.",
      },
      UserList: {
        UserList: "Relação de Usuário",
        name: "Nome",
        email: "Email",
        type: "Tipo",
        blockUnblock: "Bloquear/Desbloquear Acesso",
        user: "Usuário",
        admin: "Admin",
        table: ["Nome", "Email", "Tipo", "Bloquear/Desbloquear Acesso"],
        changeAccessTextBlock: "Você deseja bloquear o usuário ",
        changeAccessBlockConfirm: "Bloquear",
        changeAccessTitleFree: "Liberar Acesso",
        changeAccessTextFree: "Você deseja liberar o usuário ",
        changeAccessFreeConfirm: "Liberar",
        changeAccessCancel: "Cancelar",
        successResponse: (name, enabled) =>
          `O acesso do usuário ${name == null ? "" : name} foi ${
            enabled ? "liberado" : "bloqueado"
          }.`,
      },
    },
    changePassword: {
      changePassword: "Alterar Senha",
      passwordChange: "Mudança de Senha",
      successResponse: "Senha alterada com sucesso.",
      actualPassword: "Senha Atual",
      newPassword: "Nova Senha",
      passwordConfirmation: "Confirmação de Nova Senha",
      passwordMessage: baseRequiredMessage,
      confirm: "confirmar",
    },
  },
  header: {
    settings: "Configurações",
    settingsOptions: {
      integration: "Integrações",
      users: "Usuários",
      permissions: "Permissões",
      changePassword: "Alterar Senha",
    },
    exit: "Sair",
    exitConfirmationText:
      "Ao sair do sistema sua sessão será encerrada e dados salvos poderão ser perdidos.",
    exitConfirmation: "SAIR",
    exitDeny: "CANCELAR",
    filterButton: "Filtros",
    filterTitle: "Escolha os filtros que deseja aplicar:",
    productivityFilters: {
      dedicatedTeam: {
        title: "EQUIPE DEDICADA",
        yes: "Sim",
        no: "Não",
      },
      vision: {
        title: "VISÃO",
        annualLabel: "Anual",
        monthlyLabel: "Mensal",
        quarterlyLabel: "Trimestral",
      },
      period: {
        title: "PERÍODO",
        fromLabel: "De",
        toLabel: "Até",
      },
      process: {
        title: "PROCESSO",
        allLabel: "Todos",
        processLabel: "Processo",
        separationLabel: "Separação",
        checkoutLabel: "Checkout",
        loadLabel: "Carregamento",
      },
      operator: { title: "OPERADOR", placeholder: "Nome ou Matrícula" },
      dimensions: {
        title: "DIMENSÕES",
        centerLabel: "Centro",
        teamLabel: "Equipes",
        clientsLabel: "Clientes",
      },
      cancelButton: "Cancelar",
      applyButton: "Aplicar Filtros",
    },
    costsFilters: {
      vision: {
        title: "VISÃO",
        annualLabel: "Anual",
        monthlyLabel: "Mensal",
        quarterlyLabel: "Trimestral",
      },
      period: {
        title: "PERÍODO",
        fromLabel: "De",
        toLabel: "Até",
      },
      process: {
        title: "PROCESSO",
        allLabel: "Todos",
        processLabel: "Processo",
        separationLabel: "Separação",
        checkoutLabel: "Checkout",
        loadLabel: "Carregamento",
      },
      operator: { title: "OPERADOR", placeholder: "Nome ou Matrícula" },
      dimensions: {
        title: "DIMENSÕES",
        centerLabel: "Centro",
        teamLabel: "Equipes",
        clientsLabel: "Clientes",
      },
      cancelButton: "Cancelar",
      applyButton: "Aplicar Filtros",
    },
  },

  charts: {
    globalProductivity: {
      name: "Produtividade Global",
      spentHours: "Horas utilizadas",
      hoursLeft: "Horas Restantes",
      chartDescription: "Total de horas planejadas versus horas realizadas.",
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    historicProductivity: {
      name: "Produtividade Histórica",
      title: "Carga de Trabalho",
      productivityVariation: "Produtividade Realizada",
      productivityBenchmark: "Pico de produtividade",
      averageProductivity: "Produtividade Média",
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    productivityProcess: {
      name: "Processo",
      checkout: {
        planned: "Checkout horas planejadas",
        current: "Checkout horas realizadas",
      },
      separation: {
        planned: "Separação horas planejadas",
        current: "Separação horas realizadas",
      },
      load: {
        planned: "Carregamento horas planejadas",
        current: "Carregamento horas realizadas",
      },
      movement: {
        planned: "Movimentação horas planejadas",
        current: "Movimentação horas realizadas",
      },
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    productivityOrdering: {
      name: "Ranking de Produtividade",
      exportButton: "Exportar",
      orderingButtonUp: "Mais produtivos",
      orderingButtonDown: "Menos produtivos",
      csvName: "ranking_produtividade",
      table: [
        "Nome",
        "Matrícula",
        "Processo",
        "PRODUTIVIDADE MÉDIA",
        "Ranking",
        "OT's 30 DD",
        //"OT's 60 DD",
        //"OT's 90 DD",
        // "Ano",
        // "Tendência",
      ],
      tableCSV: [
        "Nome",
        "Matrícula",
        "Processo",
        "Produtividade",
        "Ranking",
        "OT's 30 DD",
        //"OT's 60 DD Checkout",
        //"OT's 60 DD Separação",
        //"OT's 60 DD Total",
        //"OT's 90 DD Checkout",
        //"OT's 90 DD Separação",
        //"OT's 90 DD Total",
        // "Ano",
        // "Tendência",
      ],
      emptyList: "Sem dados a serem exibidos, filtro não aplicado",
      process: {
        all: "Todos",
        checkout: "Checkout",
        separation: "Separação",
        shipper: "Carregamento",
      },
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    idleOrdering: {
      name: "Ordenação de Ociosidade",
      table: ["Nome", "Matrícula", "processo", "utilização"],
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    capacityGlobal: {
      name: "Capacidade Global",
      fte: "FTEs",
      occupation: "Ocupação",
      peak: "Pico",
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    historicCapacity: {
      name: "Utilização de Capacidade Histórica",
      load: "Carregamento",
      separation: "Separação",
      checkout: "Checkout",
      peak: "Pico",
      average: "Média",
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    capacityProcess: {
      name: "Detalhamento de Processos",
      separation: {
        title: "SEPARAÇÃO",
        subtitle: "Processo 1",
        firstChart: "Turno 1",
        secondChart: "Turno 2",
        thirdChart: "Turno 3",
        ideal: "Ideal",
        actual: "Real",
      },
      checkout: {
        title: "CHECKOUT",
        subtitle: "Processo 2",
        firstChart: "Turno 1",
        secondChart: "Turno 2",
        thirdChart: "Turno 3",
        ideal: "Ideal",
        actual: "Real",
      },

      load: {
        title: "CARREGAMENTO",
        subtitle: "Processo 3",
        firstChart: "Turno 1",
        secondChart: "Turno 2",
        thirdChart: "Turno 3",
        ideal: "Ideal",
        actual: "Real",
      },

      movement: {
        title: "MOVIMENTAÇÃO",
        subtitle: "Processo 4",
        firstChart: "Turno 1",
        secondChart: "Turno 2",
        thirdChart: "Turno 3",
        ideal: "Ideal",
        actual: "Real",
      },
      notFound: {
        message: baseReload.message,
        buttonLabel: baseReload.buttonLabel,
      },
    },
    costsPeriodResult: {
      topValueLabels: "Valor Roxo",
      bottomValuesLabels: "Valor Azul",
    },
    costsServing: {
      name: "Custo de Servir",
      table: {
        name: "Nome",
        code: "Código",
        hour: "Horas",
        utilization: "% de utilização",
        armCost: "Custo ARM",
        freight: "Frete",
        total: "Total",
        foreseen: "Previsto",
        result: "Resultado",
      },
    },
    idleProcessCost: {
      name: "Ociosidade por Processo",
      idleTime: "Horas ociosas: ",
      processLabel: "Processo",
      separationLabel: "Separação",
      checkoutLabel: "Checkout",
      loadLabel: "Carregamento",
    },
    periodResult: {
      name: "Resultado do Período",
      workLoad: "Carga de Trabalho",
      numbers: {
        envisaged: "Previsto",
        valueByWeight: "R$/KGs",
        weightByFteByDay: "KG/FTE/DIA",
      },
    },
    costsByDriversAndProcess: {
      name: "Custo por Dimensão e Processo",
      process: "PROCESSOS",
      dimensions: "DIMENSÕES",
      table: {
        cost: "Custo",
        dimension: "Dimensão",
        process: "Processo",
      },
    },
  },
  setup: {
    equations: {
      equationParameters: {
        title: "Alteração dos Parâmetros da Equação Planejada",
        process: "Processo",
        equationViewer: "Exibição da Equação",
        clear: "Limpar seleção",
        apply: "Aplicar Alteração",
        valueOf: "Valor de",
        separationEquation: "a + (b*x1) + (c*x2) + (d*x3) + (e*x4)",
        checkoutEquation:
          "a + (b*x1) + (c*x2) + (d*x3) + (e*x4) + (f*x5) + (g*x6) + (h*x8)",
      },
      equationTable: {
        title: "Histórico das Alterações Realizadas",
        checkout: "Checkout",
        separation: "Separação",
        separationEquation: "a + (b*x1) + (c*x2) + (d*x3) + (e*x4)",
        checkoutEquation:
          "a + (b*x1) + (c*x2) + (d*x3) + (e*x4) + (f*x5) + (g*x6) + (h*x8)",
        tableHeaders: [
          "categoria da equação",
          "Data/Hora da Alteração",
          "valor de a",
          "valor de b",
          "valor de c",
          "valor de d",
          "valor de e",
          "valor de f",
          "valor de g",
          "valor de h",
        ],
      },
    },
  },
  report: {
    reportFilter: {
      title: "Filtros de Relatório",
      period: "Período",
      clear: "Limpar",
      apply: "Aplicar Filtro",
      from: "De",
      to: "Até",
    },
    reportTable: {
      title: "Modelo de Relatório",
      exportButton: "Exportar",
      noData: "Sem dados para o período",
      csvName: "Relatorio",
      tableHeaders: [
        "data ot",
        "nº ot",
        "op. checkout",
        "matrícula",
        "Checkout - Score de produtividade (%)",
        "Checkout planejado(s)",
        "Checkout realizado(s)",
        "op. separação",
        "matrícula",
        "Separação- Score de produtividade (%)",
        "Separação planejado(s)",
        "Separação realizado(s)",
      ],
    },
  },
};

export default ptBr;
