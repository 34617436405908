import { call, put } from "redux-saga/effects";
import { Alerts, Filters } from "../../../lib";
import { ResponseError, api } from "../../../services";
import { Creators } from "./index";

export default function* globalProductivityRequest({
  filters = {},
  cancelToken,
}) {
  try {
    filters.shippingStartDate = Filters.fixDateToRequest(
      filters.shippingStartDate,
      -6
    );
    filters.shippingEndDate = Filters.fixDateToRequest(
      filters.shippingEndDate,
      0
    );
    filters.id = filters.id != null ? filters.id : -1;
    filters.type = filters.type != null ? filters.type : "ALL";
    filters.equalsProcess =
      filters.equalsProcess != null ? filters.equalsProcess : false;

    const response = yield call(
      api.getGlobalProductivityChart,
      filters,
      cancelToken
    );

    if (response.error === "OK") {
      Alerts.alertWarning("Sem registro no período.");
      yield put(Creators.globalProductivityFailure());
      return;
    }

    yield put(Creators.globalProductivitySuccess(response.globalProductivity));
    yield put(
      Creators.historicProductivitySuccess(response.productivityHistory)
    );
    yield put(
      Creators.orderingProductivitySuccess(response.productivityByOperator)
    );
    yield put(
      Creators.productivityProcessSuccess(response.productivityBySector)
    );
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.globalProductivityFailure());
  }
}
