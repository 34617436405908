import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Fonts, MediaQueries, Spacing } from "../../../../config";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";

const Facebook = styled(FacebookIcon)((props) => ({
  display: "flex",
  width: Spacing(3),
  height: Spacing(3),

  color: "white",
}));

const LinkedIn = styled(LinkedInIcon)((props) => ({
  display: "flex",
  width: Spacing(3),
  height: Spacing(3),

  color: "white",
}));
const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",

  height: "fit-content",
  maxWidth: props.theme.spacing(120.75),
  justifyContent: "flex-start",
  gap: Spacing(2),
  [MediaQueries.smDown]: { justifyContent: "flex-start" },
}));

const Content = styled.div((props) => ({
  display: "flex",
  width: "100%",
  height: "100%",
}));

const Title = styled(Typography)((props) => ({
  fontFamily: Fonts.black,
  fontSize: props.state.mdUp ? Fonts.sizes.extraLarge : Fonts.sizes.mediumlarge,
  color: "#FFF",
  margin: 0,
}));

const SubTitle = styled(Typography)(({ $alternative, theme, state }) => ({
  color: $alternative ? "#0D518E" : "#fff",
  fontFamily: Fonts.medium,
  fontSize: state.mdUp ? theme.spacing(3) : Fonts.sizes.regular,
  margin: 0,
  marginBottom: $alternative ? 0 : Spacing(1),
}));

const ButtonDiv = styled.div(({ theme }) => ({
  display: "flex",
  padding: 2,
  paddingTop: theme.spacing(2.5),
}));
const SubText = styled.div((props) => ({
  padding: props.theme.spacing(0.625),
  backgroundColor: "white",
  width: "fit-content",
}));
const ButtonDemo = styled(Button)((props) => ({
  display: "flex",
  background: "transparent",
  color: "#ffffff",
  fontFamily: Fonts.medium,
  fontSize: "18px",
  padding: "20px",
  paddingInline: "64px",
  border: "2px solid #ffffff",
  "&:hover": {
    backgroundColor: "#ccd1e6",
  },
  [MediaQueries.smDown]: {
    padding: "16px",
    paddingInline: "48px",
    fontSize: "16px",
  },
}));

const RewardContainer = styled.div((props) => ({
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "row",
  [MediaQueries.smDown]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Styles = {
  RewardContainer,
  Container,
  Content,
  Title,
  SubTitle,
  //BackgroundImage,
  //ImgSrc,
  ButtonDemo,
  ButtonDiv,
  Facebook,
  LinkedIn,
  SubText,
};

export default Styles;
