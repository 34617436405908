import React from "react";
import { Typography } from "@material-ui/core";

import styled from "styled-components";
import { Fonts, MediaQueries, Themes } from "../../config";

const StyledText = styled(Typography)((props) => {
  return {
    fontFamily: props.$fontFamily ? props.$fontFamily : Fonts.light,
    fontSize: props.$fontSize ? props.$fontSize : Fonts.sizes.small,
    color: props.$color ? props.$color : Themes.Light.palette.text.primary,
    cursor: props.pointer && "pointer",
    textAlign: props.isOdd ? "right" : "left",
    whiteSpace: "normal",
    overflow: "hidden",
    wordWrap: "break-word",
    [MediaQueries.lgDown]: {
      textAlign: "left",
    },
    [MediaQueries.smDown]: {
      fontSize: props.$fontSize
        ? `calc(${props.$fontSize}px * 0.75)`
        : Fonts.sizes.smaller,
    },

    ...props.style,
  };
});

function CustomText({ children, ...props }) {
  return (
    <StyledText
      pointer={props.pointer}
      $fontFamily={props.fontFamily}
      $fontSize={props.fontSize}
      $color={props.color}
      style={props.style}
      $isOdd={props.isOdd}
      $customQuery={props.customQuery}
      {...props}
    >
      {children}
    </StyledText>
  );
}

export default React.memo(CustomText);
