import React, { useCallback, useEffect } from "react";
import Styles from "../styles/Styles";
import { Header } from "../components";
import Pages from "../presentations";

import Contact from "../components/contact";
import { CustomText, FontStyles } from "../../../components";
import { Fonts, Spacing } from "../../../config";
import ToolsInfo from "../components/toolsInfo";
import Functionalities from "../components/functionalities";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { ReactComponent as Whats } from "../../../assets/icons/FAB-Whats.svg";
import emailjs from "emailjs-com";
import { Alerts } from "../../../lib";
import fonts from "../../../config/fonts";
import { Fab, styled } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { ChevronDown } from "../../../config/icons";
import { Mail } from "@material-ui/icons";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    ...FontStyles.medium12
  },
}));
function HomePage() {
  const [headerH, setHeaderH] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleChange = () => {};

  const sendData = async (data) => {
    setLoading(true);
    await emailjs
      .send(
        "service_iq0zspo",
        "template_5ld38lc",
        data,
        "user_tAEzs8WC7s16E9zPTJtnZ"
      )
      .then(
        function (response) {
          Alerts.alertSuccess([
            "Solicitação enviada com sucesso.",
            "Em breve nossa equipe entrara em contato.",
          ]);
        },
        function (err) {
          Alerts.alertError([
            "Erro ao solicitar contato.",
            "Tente novamente mais tarde.",
          ]);
        }
      );
    setLoading(false);
  };

  const Mount = useCallback(() => {
    setHeaderH(document.getElementById("navHeader").offsetHeight);
  }, []);

  useEffect(Mount, [Mount]);
  const openWhats = () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://wa.me/5504891114252";
    a.click();
  };
  return (
    <div style={{ overflow: "auto", overflowX: "hidden" }}>
      <LightTooltip title="Voltar ao topo da página" placement="left">
        <Fab
          target="_blank"
          onClick={() => document.getElementById("menu")?.scrollIntoView()}
          style={{
            position: "absolute",
            bottom: Spacing(16),
            right: Spacing(0.25),

            marginInline: `clamp(${Spacing(2)}px , 7vw, ${Spacing(18.75)}px)`,
            marginBlock: `clamp(${Spacing(2)}px , 4vh, ${Spacing(8)}px)`,
            backgroundColor: "#0D518E",
            color: "white",
            padding: 0,

            width: Spacing(5.25),
            height: Spacing(5.25),
            zIndex: 999,
          }}
          aria-label="add"
        >
          <ChevronDown
            style={{
              transform: "scaleY(-1)",
              color: "white",
              filter: "brightness(0) contrast(1) invert(1)",
            }}
          />
        </Fab>
      </LightTooltip>
      <LightTooltip title="Contato via E-mail" placement="left">
        <Fab
          target="_blank"
          onClick={() => document.getElementById("contato")?.scrollIntoView()}
          style={{
            position: "absolute",
            bottom: Spacing(8),
            right: Spacing(0.25),

            marginInline: `clamp(${Spacing(2)}px , 7vw, ${Spacing(18.75)}px)`,
            marginBlock: `clamp(${Spacing(2)}px , 4vh, ${Spacing(8)}px)`,
            backgroundColor: "#0D518E",

            padding: 0,

            width: Spacing(5.25),
            height: Spacing(5.25),
            zIndex: 999,
          }}
          aria-label="add"
        >
          <Mail style={{ color: "white" }} />
        </Fab>
      </LightTooltip>

      <LightTooltip title="Contato via WhatsApp" placement="left">
        <Fab
          target="_blank"
          onClick={openWhats}
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,

            marginInline: `clamp(${Spacing(2)}px , 7vw, ${Spacing(18.75)}px)`,
            marginBlock: `clamp(${Spacing(2)}px , 4vh, ${Spacing(8)}px)`,
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,

            width: Spacing(5.75),
            height: Spacing(5.75),
            zIndex: 999,
          }}
          aria-label="add"
        >
          <Whats />
        </Fab>
      </LightTooltip>

      <Styles.Background $width={headerH} id="menu">
        <Styles.BackgroundImage />
        <Header />
        <Styles.Content>
          <Pages.Home onPress={handleChange} />
          {/*  <Styles.BoxIcons>
            <Styles.LinkedIn />
            <Styles.Facebook />
          </Styles.BoxIcons> */}
        </Styles.Content>
      </Styles.Background>

      <div id="beneficios">
        <ToolsInfo />
      </div>
      <div id="funcionalidades">
        <Functionalities />
      </div>
      <div id="contato">
        <Contact headerH={headerH} sendData={sendData} loading={loading} />
      </div>

      <Styles.Contact
        style={{ backgroundColor: "#202436", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: Spacing(8.25),
          }}
        >
          <Styles.Logo noMargin />
          <CustomText
            style={{ cursor: "pointer", display: "flex" }}
            fontFamily={Fonts.semibold}
            fontSize={Spacing(2)}
            onClick={() => {
              document.getElementById("menu")?.scrollIntoView();
            }}
          >
            Voltar ao topo <ArrowUpwardIcon />
          </CustomText>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <CustomText
              fontFamily={fonts.medium}
              style={{ display: "flex", paddingBottom: Spacing(1) }}
            >
              Av Rio Branco, Av. Pref. Osmar Cunha, 416 - Centro, Florianópolis
              - SC, 88015-200
            </CustomText>
            <CustomText fontFamily={fonts.roman}>
              contato@laboranalytics.com.br
            </CustomText>
          </div>
        </div>
      </Styles.Contact>
    </div>
  );
}

export default HomePage;
