import { Base64 } from "../lib";
import { history } from "../store";
import Constants from '../config/constants'

export const prefix = "/demo"

export const paths = {
  homePage: "/",
  homePreLogin: prefix + "/",
  login: prefix + "/login",
  home: prefix + "/",
  forgotPassword: prefix + "/forgot_password",
  productivity: prefix + "/productivity",
  capacity: prefix + "/capacity",
  costs: prefix + "/costs",
  opportunity: prefix + "/opportunity",
  changePassword: prefix + "/change_password",
  userRegister: prefix + "/user_register",
  userList: prefix + "/user_list",
  report: prefix + "/report",
  setupEquation: prefix + "/setup_equation",
};
export const navigateTo = {
  Home: () => navigate(paths.home),
  HomePreLogin: () => navigate(paths.homePreLogin),
  Landing: () => navigate(paths.login),
  ForgotPassword: () => navigate(paths.forgotPassword),
  Productivity: () => navigate(paths.productivity),
  Capacity: () => navigate(paths.capacity),
  Costs: () => navigate(paths.costs),
  Opportunity: () => navigate(paths.opportunity),
  ChangePassword: () => navigate(paths.changePassword),
  UserRegister: () => navigate(paths.userRegister),
  UserList: () => navigate(paths.userList),
  SetupEquation: () => navigate(paths.setupEquation),
  Report: () => navigate(paths.report),
  exampleWithParams: (queryParam) =>
    navigate(`/example/${Base64.encode(queryParam)}`),
};

export const replaceTo = {
  Home: () => replace(paths.home),
  HomePreLogin: () => replace(paths.homePreLogin),
  Landing: () => replace(paths.login),
  ForgotPassword: () => replace(paths.forgotPassword),
  Productivity: () => replace(paths.productivity),
  Capacity: () => replace(paths.capacity),
  Costs: () => replace(paths.costs),
  Opportunity: () => replace(paths.opportunity),
  ChangePassword: () => replace(paths.changePassword),
  UserRegister: () => replace(paths.userRegister),
  UserList: () => replace(paths.userList),
  SetupEquation: () => replace(paths.setupEquation),
  Report: () => replace(paths.report),
  exampleWithParams: (queryParam) =>
    replace(`/example/${Base64.encode(queryParam)}`),
};

export function navigate(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.push({ pathname });
}

export function replace(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.replace({ pathname });
}

export function goBack() {
  history.goBack();
}

export function goFoward() {
  history.goForward();
}
