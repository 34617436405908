import React from "react";
import Styles from "../styles/Styles";

import { CustomText } from "../../../components";
import { Fonts, MediaQueries, Spacing } from "../../../config";

import fonts from "../../../config/fonts";
import colors from "../../../config/colors";
import Img0 from "../../../assets/images/img-index-01-funcionalidades.png";
import Img1 from "../../../assets/images/img-index-02-funcionalidades.png";
import Img2 from "../../../assets/images/img-index-03-funcionalidades.png";
import Img3 from "../../../assets/images/img-index-04-funcionalidades.png";
import Img4 from "../../../assets/images/img-index-05-funcionalidades.png";

function isOdd(num) {
  return num % 2;
}
function Functionalities() {
  const cards = [
    {
      title: "Análise de Produtividade Granular",
      subText:
        "Tenha uma visão detalhada do desempenho da sua operação por unidade, depositante, clientes, atividade, turno de trabalho e colaborador.",
      img: Img0,
    },
    {
      title: "Análise de Ocupação e Ociosidade",
      subText:
        "Obtenha insights valiosos sobre a utilização dos recursos humanos em sua operação. Identifique momentos de ociosidade e otimize a alocação de colaboradores para melhorar a eficiência operacional.",
      img: Img1,
    },
    {
      title: "Monitoramento em Tempo Real",
      subText:
        "Acompanhe o desempenho da sua operação logística em tempo real. Tenha acesso a informações atualizadas sobre indicadores-chave de desempenho.",
      img: Img2,
    },
    {
      title: "Integração com Sistemas de Gestão",
      subText:
        "Nosso LMS se integra perfeitamente com diversos sistemas de gestão, como ERP, WMS e relógios de ponto.",
      img: Img3,
    },
    {
      title: "Relatórios Personalizados",
      subText:
        "Crie relatórios personalizados de acordo com suas necessidades específicas. Nossa solução oferece opções flexíveis de relatórios, permitindo que você visualize e analise dados relevantes de forma clara e concisa.",
      img: Img4,
    },
  ];
  return (
    <>
      <Styles.FunctionalitiesContainer>
        <Styles.ToolInfo>
          <CustomText
            style={{
              textAlign: "center",
              paddingBottom: Spacing(2.5),
              [MediaQueries.smDown]: {
                fontSize: Spacing(2.85)
              },
            }}
            fontFamily={Fonts.semibold}
            fontSize={Spacing(5.25)}
            color={"#0D518E"}
          >
            Funcionalidades
          </CustomText>
          <CustomText
            style={{ textAlign: "center" }}
            fontFamily={Fonts.roman}
            fontSize={Spacing(3)}
            color="#1E1D2B"
          >
            Com recursos avançados, fornecemos as ferramentas necessárias para
            impulsionar a produtividade e a eficiência do seu negócio. Confira
            as principais funcionalidades do nosso sistema:
          </CustomText>
        </Styles.ToolInfo>

        <Styles.FunctionalitiesContent>
          {cards?.map((item, index) => (
            <Styles.FunctionalitiesBox $isOdd={isOdd(index)}>
              <Styles.BenefíciosContentImg style={{ flex: 1 }}>
                <Styles.FunctionalitiesImg id="degre-img" src={item?.img} />
                <Styles.BenefíciosImgDegrade></Styles.BenefíciosImgDegrade>
              </Styles.BenefíciosContentImg>

              <Styles.FunctionalitiesText $isOdd={isOdd(index)}>
                <CustomText
                  fontSize={Spacing(3)}
                  fontFamily={fonts.bold}
                  color={colors.darkBlue2}
                >
                  {item?.title}
                </CustomText>
                <div
                  style={{
                    border: "2px solid #0D518E",
                    borderRadius: "5px",

                    width: Spacing(6.25),
                  }}
                ></div>

                <CustomText
                  isOdd={isOdd(index)}
                  fontSize={Spacing(3)}
                  fontFamily={fonts.roman}
                  color={"#1E1D2B"}
                  style={{}}
                >
                  {item?.subText}
                </CustomText>
              </Styles.FunctionalitiesText>
            </Styles.FunctionalitiesBox>
          ))}
        </Styles.FunctionalitiesContent>
      </Styles.FunctionalitiesContainer>
    </>
  );
}

export default Functionalities;
