import React from "react";
import { Setup } from "../features";
import { OpportunityStyles as Styles } from "./styles";
import { Content } from "./styles/defaultStyles";

function SetupEquation() {
  return (
    <Styles.Container>
      <Content $startCol={1} $endCol={12}>
        <Setup.Equation.EquationParameters />
      </Content>
      <Content $startCol={1} $endCol={12} $startRow={2} $endRow={16}>
        <Setup.Equation.EquationTable />
      </Content>
    </Styles.Container>
  );
}

export default SetupEquation;
