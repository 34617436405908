import createReducers from "../../../../store/helpers/createPageReducer";
import EquationsRequestLoad from "./EquationsRequestLoad";
import EquationsRequestSave from "./EquationsRequestSave";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "EquationsRequestLoad",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: EquationsRequestLoad,
    },
    {
      name: "EquationsRequestLoadSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        equationsHistoryData: data,
      }),
    },
    {
      name: "EquationsRequestLoadFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "EquationsRequestSave",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: EquationsRequestSave,
    },
    {
      name: "EquationsRequestSaveFinish",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
  ],
  {
    isFetching: false,
    equationsHistoryData: null,
  }
);

export { Creators, reducers, sagas };
