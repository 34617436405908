import { call, put } from "redux-saga/effects";
import { ResponseError, api } from "../../../../services";
import { Creators } from "./index";

export default function* EquationsRequestLoad({ data }) {
  try {
    delete data.processS;
    delete data.equationExib;

    yield call(api.sendEquationsSetup, data);
    yield put(Creators.EquationsRequestLoad())
    yield put(Creators.EquationsRequestSaveFinish());
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.EquationsRequestSaveFinish());
  }
}
