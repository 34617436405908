import styled from "styled-components";
import PexelBg from "../../../assets/images/img-03.png";

import { Fonts, Logos, MediaQueries, Spacing } from "../../../config";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Mail } from "../../../config/icons";
import { Button, Typography } from "@material-ui/core";
import ShapesImage from "../../../assets/images/comp-background-triangles.png";
import Triangles from "../../../assets/images/img-composition-triangles.svg";
import ButtonContainedComponent from "../../../components/buttons/ButtonContained";
const Facebook = styled(FacebookIcon)((props) => ({
  display: "flex",
  width: Spacing(3),
  height: Spacing(3),

  color: "white",
}));
const MailSVG = styled(Mail)((props) => ({
  height: "auto",
  width: "100%",
  margin: 0,
  maxWidth: Spacing(58.125),
  paddingInline: Spacing(2),
  [MediaQueries.smDown]: {
    width: "80%",
    margin: Spacing(5),
    marginBlock: Spacing(8),
  },
}));
const LinkedIn = styled(LinkedInIcon)((props) => ({
  display: "flex",
  width: Spacing(3),
  height: Spacing(3),

  color: "white",
}));
const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
}));

const Background = styled.div((props) => {
  return {
    position: "relative",
    zIndex: 1,
    maxWidth: "100%",
    minHeight: `86vh`,
    height: "fit-content",
    paddingInline: `clamp(${Spacing(2)}px , 15vw, ${Spacing(37.5)}px)`,
    paddingBlock: `clamp(${Spacing(2)}px , 9vh, ${Spacing(12.5)}px)`,
    display: "flex",
    flexDirection: "column",
    [MediaQueries.smDown]: {
      paddingInline: `clamp(${Spacing(2)}px , 5vw, ${Spacing(37.5)}px)`,
      paddingBlock: `clamp(${Spacing(2)}px , 7vh, ${Spacing(12.5)}px)`,
    },
  };
});

const BackgroundImage = styled.div((props) => {
  return {
    position: "absolute",
    zIndex: -1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    opacity: 0.66,

    backgroundImage: `linear-gradient(180deg, #0D518E44  0%, #0D518E 100%), url(${PexelBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "cover",
    maxWidth: "100%",

    height: `100%`,

    [MediaQueries.smDown]: {
      backgroundSize: "600%",
      backgroundImage: 'linear-gradient(180deg, #0D518E44  0%, #0D518E 100%)',
    },
  };
});
const Contact = styled.div((props) => {
  return {
    display: "flex",
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    minHeight: !props.minHeight && `calc(100vh - ${props.$width}px)`,
    height: "fit-content",
    padding: `clamp(${Spacing(4)}px , 7vh, ${Spacing(10)}px)`,
    paddingInline: `clamp(${Spacing(2)}px , 15vw, ${Spacing(37.5)}px)`,

    flexDirection: "column",
    [MediaQueries.smUp]: {
      flexDirection: "column",
    },
    [MediaQueries.smDown]: {
      paddingInline: `clamp(${Spacing(2)}px , 5vw, ${Spacing(37.5)}px)`,
    },
  };
});
const Content = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  flex: 1,
  [MediaQueries.smDown]: { alignItems: "flex-start", paddingTop: Spacing(2.5) },
}));

const BoxIcons = styled.div((props) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: Spacing(3.75),
}));
const Title = styled(Typography)((props) => ({
  fontFamily: Fonts.semibold,
  fontSize: Fonts.sizes.mediumlarge,
  color: "#0D518E",
  paddingBottom: Spacing(1.25),
  margin: 0,
  [MediaQueries.mdUp]: {
    fontSize: Fonts.sizes.extraLarge,
  },
}));

const SubTitle = styled(Typography)((props) => ({
  color: "#1E1D2B",
  fontFamily: Fonts.roman,
  fontSize: Fonts.sizes.large,
  margin: 0,
  [MediaQueries.mdUp]: {
    fontSize: Spacing(3),
  },
  [MediaQueries.smDown]: {
    fontSize: Spacing(2.25),
  },
}));

const ExtraTitle = styled(Typography)((props) => ({
  color: "#1E1D2B",
  fontFamily: Fonts.roman,
  fontSize: Fonts.sizes.large,
  maxWidth: Spacing(142),
  margin: 0,
  [MediaQueries.mdUp]: {
    fontSize: Spacing(2.5),
  },
  [MediaQueries.smDown]: {
    fontSize: Spacing(2.25),
  },
}));

const HowWorks = styled.div((props) => {
  return {
    display: "flex",
    backgroundColor: "#EEF9FF",
    marginBlock: Spacing(1.5),
    padding: `clamp(${Spacing(2)}px , 55vw, ${Spacing(12.5)}px)`,
    flexDirection: "column",
    gap: Spacing(3),
    [MediaQueries.smUp]: {
      flexDirection: "row",
    },
  };
});

const TitleBorder = styled.div((props) => ({
  borderBottom: "10px solid #5AB6E5",
  width: "auto",
  [MediaQueries.mdUp]: {
    width: "max-content",
  },
}));

const ToolInfo = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
}));

const ToolContainer = styled.div(({ $alternative }) => ({
  display: "flex",

  backgroundColor: $alternative ? "#094377" : "#EEF9FF",
  flexDirection: "column",
  padding: `clamp(${Spacing(4)}px , 7vh, ${Spacing(10)}px)`,
  paddingInline: `clamp(${Spacing(2)}px , 17vw, ${Spacing(41)}px)`,
  paddingBottom: `clamp(${Spacing(4)}px , 14vh, ${Spacing(20)}px)`,
  [MediaQueries.smDown]: {
    paddingInline: `clamp(${Spacing(2)}px , 5vw, ${Spacing(41)}px)`,
  },
}));
const FunctionalitiesContainer = styled.div(({ $alternative }) => ({
  display: "flex",

  backgroundColor: "#E5F6FF",
  flexDirection: "column",
  padding: `clamp(${Spacing(4)}px , 7vh, ${Spacing(10)}px)`,
  paddingInline: `clamp(${Spacing(2)}px , 15vw, ${Spacing(37.5)}px)`,
  paddingBottom: `clamp(${Spacing(4)}px , 14vh, ${Spacing(20)}px)`,
  [MediaQueries.smDown]: {
    paddingInline: `clamp(${Spacing(2)}px , 5vw, ${Spacing(41)}px)`,
  },
}));
const ToolData = styled.div((props) => ({
  flex: 1,
  padding: `clamp(${Spacing(4)}px , 5vw, ${Spacing(12.5)}px)`,
  backgroundColor: "#307add",
  backgroundImage: `url(${ShapesImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom right",
  backgroundSize: "contain",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  [MediaQueries.mdUp]: {
    flex: 3,
  },
}));

const PaddingBlocks = styled.div((props) => ({
  gap: Spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

const Logo = styled(Logos.LogoWhite)((props) => {
  return {
    height: MediaQueries.mdUp ? "47px" : "32px",
    marginBlock: props?.noMargin ? 0 : Spacing(2),
    opacity: 1,

    [MediaQueries.smUp]: {
      marginBlock: props?.noMargin ? 0 : Spacing(3.25),
    },
  };
});

const ButtonContained = styled(Button)`
  display: flex;
  background: #0d518e;
  color: #ffffff;
  font-family: ${Fonts.medium};
  font-size: 18px;

  padding: clamp(12px, 1vw, 20px);
  padding-inline: clamp(5px, 2vw, 52px);
  &:hover {
    background-color: #ccd1e6;
  }
`;

const ButtonOutlined = styled(Button)`
  display: flex;
  background: transparent;
  color: #5ab6e5;
  font-family: ${Fonts.medium};
  font-size: 16px;
  padding: clamp(12px, 1vw, 22px);
  padding-inline: clamp(5px, 1vw, 36px);
  border: 2px solid #5ab6e5;
  &:hover {
    background-color: #ccd1e6;
  }
`;

const BenefíciosContent = styled.div((props) => ({
  display: "flex",
  marginTop: Spacing(7.5),
  gap: Spacing(6.25),

  [MediaQueries.lgDown]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));
const FunctionalitiesContent = styled.div((props) => ({
  display: "flex",
  marginTop: Spacing(7.5),
  gap: Spacing(6.25),
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));
const BenefíciosContentImg = styled.div((props) => ({
  position: "relative",
  display: "inline-block",
  height: "fit-content",
}));
const BenefíciosImg = styled.img((props) => ({
  maxWidth: Spacing(73.125),
  display: "block",
  width: "100%",
  height: "auto",
}));
const FunctionalitiesImg = styled.img((props) => ({
  display: "block",
  width: "100%",
  height: "auto",
}));
const BenefíciosImgDegrade = styled.div((props) => ({
  content: "",
  position: "absolute",
  top: 0,
  left: 0,
  width: "40%",
  height: "100%",
  backgroundImage: "linear-gradient(270deg, #0D518E00 0%, #0D518E99 100%)",
}));

const BenefíciosContentText = styled.div((props) => ({
  maxWidth: "50%",
  gap: Spacing(2.5),
  display: "flex",
  flexDirection: "column",
  minHeight: "384px",
  [`@media only screen and (max-width: ${1468}px)`]: {
    minHeight: "328px",
  },
  [`@media only screen and (max-width: ${1281}px)`]: {
    minHeight: "264px",
  },
  [`@media only screen and (max-width: ${700}px)`]: {
    minHeight: "300px",
  },
  [MediaQueries.lgDown]: {
    maxWidth: "100%",
  },
}));

const BenefíciosContentButtons = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  gap: Spacing(2.5),

  [MediaQueries.smDown]: {
    flexDirection: "column",
  },
}));

const TriangleLeft = styled.div((props) => ({
  position: "absolute",
  top: 0,
  height: "100%",
  width: "50%",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "scroll",
  left: 0,
  backgroundImage: `url(${Triangles})`,
  backgroundPosition: "-243px -326px",
  backgroundSize: `550px auto`,
  opacity: 0.33,
}));

const TriangleRight = styled.div((props) => ({
  position: "absolute",
  top: 0,
  height: "100%",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "scroll",
  left: 0,
  backgroundImage: `url(${Triangles})`,
  backgroundPosition: "bottom",
  backgroundSize: "contain",
  opacity: 1,
}));

const OperationStatus = styled.div((props) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: Spacing(8.125),
  paddingBlock: Spacing(2.5),
  [MediaQueries.smDown]: {
    flexDirection: "column",
  },
}));

const StatusValues = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));
const StatusValuesBox = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  gap: Spacing(1),
  paddingBottom: Spacing(1.75),
}));

const FunctionalitiesBox = styled.div(({ $isOdd }) => ({
  display: "flex",
  gap: Spacing(6.25),
  justifyContent: "center",
  flexDirection: $isOdd ? "row-reverse" : "row",
  alignItems: "center",
  [MediaQueries.lgDown]: {
    flexDirection: "column",
  },
}));

const FunctionalitiesText = styled.div(({ $isOdd }) => ({
  gap: Spacing(2.5),
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: $isOdd ? "flex-end" : "flex-start",
  [MediaQueries.lgDown]: {
    maxWidth: "100%",
    alignItems: "flex-start",
  },
}));

const ContactForm = styled.div(({ $isOdd }) => ({
  display: "flex",
  flexDirection: "row",
  paddingTop: Spacing(4.5),
  [MediaQueries.smDown]: {
    flexDirection: "column-reverse",
    paddingTop: Spacing(2.5),
  },
}));
const SubText = styled.p((props) => {
  console.log(props);
  return {
    padding: 0,
    margin: 0,
    fontFamily: props.fontFamily,
    fontSize: props.fontSize + "px",
    color: props.color,

    whiteSpace: "normal",
    overflow: "hidden",
    wordWrap: "break-word",
    [MediaQueries.lgDown]: {
      textAlign: "left",
    },

    [`@media only screen and (max-width: ${1476}px)`]: {
      fontSize: `calc(${props.fontSize}px * 0.80)`,
    },
    ...props.style,
  };
});

const ContainedFormButton = styled(ButtonContainedComponent)((props) => ({
  marginTop: props.theme.spacing(1.25),
  marginBottom: props.theme.spacing(1.25),
  padding: props.theme.spacing(2.75, 6.5),
  backgroundColor: "#0D518E",
  width: "fit-content",
  alignSelf: "flex-end",
  [MediaQueries.smDown]: {
    width: "100%",
    marginTop: 0,
    padding: props.theme.spacing(1.5, 4.5),
  },
}));

const Styles = {
  Container,
  Background,
  Content,
  //BackgroundImage,
  //ImgSrc,
  Facebook,
  LinkedIn,
  BoxIcons,
  Contact,
  Title,
  SubTitle,
  ExtraTitle,
  HowWorks,
  TitleBorder,
  ToolInfo,
  ToolData,
  PaddingBlocks,
  Logo,
  ToolContainer,
  MailSVG,
  BackgroundImage,
  ButtonContained,
  ButtonOutlined,
  BenefíciosContent,
  BenefíciosContentImg,
  BenefíciosImg,
  BenefíciosImgDegrade,
  BenefíciosContentText,
  BenefíciosContentButtons,
  TriangleLeft,
  TriangleRight,
  OperationStatus,
  StatusValues,
  StatusValuesBox,
  FunctionalitiesImg,
  FunctionalitiesContent,
  FunctionalitiesContainer,
  FunctionalitiesBox,
  FunctionalitiesText,
  ContactForm,
  SubText,
  ContainedFormButton,
};

export default Styles;
