import { Box, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";
import {
  ButtonContained,
  ButtonOutlined,
  Input,
  InputCellphone,
  InputEmail,
  InputNumber,
  InputRowContainer,
} from "../../../components";
import ResponsiveComponent from "../../../components/others/ResponsiveComponent";
import { FormHolder } from "../../../FormConfig";
import Styles from "./Styles/DemoRequestStyle";
import emailjs from "emailjs-com";
import { Alerts } from "../../../lib";

class DemoRequest extends ResponsiveComponent {
  constructor(props) {
    super(props);

    this.formConfig = null;
    this.state = { loading: false, ...this.responseComponent_getSizes() };
  }
  render() {
    const responseForm = async () => {
      const { data, hasError } = this.formConfig.testErrorsAndReturnData();
      if (!hasError) {
        this.setState({ loading: true });
        await emailjs
          .send(
            "service_iq0zspo",
            "template_5ld38lc",
            data,
            "user_tAEzs8WC7s16E9zPTJtnZ"
          )
          .then(
            function (response) {
              Alerts.alertSuccess([
                "Solicitação enviada com sucesso.",
                "Em breve nossa equipe entrara em contato.",
              ]);
            },
            function (err) {
              Alerts.alertError([
                "Erro ao solicitar contato.",
                "Tente novamente mais tarde.",
              ]);
            }
          );
        this.setState({ loading: false });
      }
    };

    return (
      <Styles.Container>
        <Styles.Left state={this.state}>
          <Box>
            <IconButton
              edge={"start"}
              size={"medium"}
              onClick={this.props.handleChange}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          <Styles.Title>Demonstração Gratuita</Styles.Title>
          <Styles.SubTitle>
            Para agendar, por favor, informe seus dados para contato abaixo:
          </Styles.SubTitle>
          <FormHolder formRef={(ref) => (this.formConfig = ref)}>
            <Input
              alternativeColors={3}
              name="name"
              disableError
              required
              margin={"dense"}
              inputLabel="Nome"
              placeholder={"Nome"}
            />
            <InputEmail
              alternativeColors={3}
              name="email"
              disableError
              required
              margin={"dense"}
              inputLabel="Email"
              placeholder={"Email"}
            />
            <InputRowContainer md={6}>
              <InputCellphone
                name="telefone"
                alternativeColors={3}
                required
                margin={"dense"}
                disableError
                inputLabel="Telefone"
                placeholder={"Telefone"}
              />
              <Input
                fullWidth
                alternativeColors={3}
                required
                name="cargo"
                disableError
                margin={"dense"}
                inputLabel="Cargo"
                placeholder={"Cargo"}
              />
            </InputRowContainer>
            <InputRowContainer md={6}>
              <Input
                fullWidth
                name="company"
                required
                alternativeColors={3}
                disableError
                margin={"dense"}
                inputLabel="Empresa"
                placeholder={"Empresa"}
              />
              <InputNumber
                name="nrFuncio"
                alternativeColors={3}
                required
                inputLabel="Nº de funcionários"
                placeholder={"Nº de funcionários"}
                disableError
                margin={"dense"}
              />
            </InputRowContainer>
            <Input
              name="obs"
              alternativeColors={3}
              required
              inputLabel="Comentários"
              multiline
              rows={6}
              margin={"dense"}
              placeholder={"Comente aqui sua necessidade."}
              disableError
            />
            <ButtonContained
              name="connect"
              type="submit"
              onClick={responseForm}
              loading={this.state.loading}
              disabled={this.state.loading}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Enviar
            </ButtonContained>
            <ButtonOutlined onClick={this.props.handleChange}>
              Voltar
            </ButtonOutlined>
          </FormHolder>
        </Styles.Left>
        {this.state.mdUp ? <Styles.Right /> : null}
      </Styles.Container>
    );
  }
}

export default DemoRequest;
