import React from "react";

function TwoLine({ v1, v2 }) {
  return (
    <div>
      <div>{v1}</div>
      <div>{v2}</div>
    </div>
  );
}

export default TwoLine;
