import styled from "styled-components";

const GraphContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    padding: spacing(4, 4, 4, 4),
    flex: 1,
    // height: "100%",
  };
});

const Styles = {
  GraphContainer,
};

export default Styles;
