import React from "react";
import { Home } from "../features";

function HomePage() {
  return (
    <>
      <Home />
    </>
  );
}

export default HomePage;
