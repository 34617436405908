import { createReducers } from "../../store/helpers";
import logoutFunction from "./logoutFunction";
import getGlobalStatus from "./getGlobalStatus";
import getUserInfos from "./getUserInfos";
import getOperadoresRequest from "./getOperadoresRequest";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "globalLogout",
      function: (state) => ({
        ...state,
        userInfos: {},
      }),
      sagaFunction: logoutFunction,
    },

    {
      name: "getGlobalStatus",
      function: (state) => ({
        ...state,
      }),
      sagaFunction: getGlobalStatus,
    },

    {
      name: "setIsLoggedIn",
      params: ["isLoggedIn"],
      function: (state, { isLoggedIn }) => ({
        ...state,
        isLoggedIn,
      }),
    },

    {
      name: "getUserInfosRequest",
      function: (state) => ({
        ...state,
        isFetchingUserInfos: true,
      }),
      sagaFunction: getUserInfos,
    },
    {
      name: "getUserInfosSuccess",
      params: ["userInfos"],
      function: (state, { userInfos }) => ({
        ...state,
        userInfos,
        isFetchingUserInfos: false,
      }),
    },
    {
      name: "getUserInfosFailure",
      function: (state) => ({
        ...state,
      }),
    },
    {
      name: "getOperadoresRequest",
      function: (state) => ({
        ...state,
        isFetchingOperator: true,
      }),
      sagaFunction: getOperadoresRequest,
    },
    {
      name: "getOperadoresSuccess",
      params: ["operatorData"],
      function: (state, { operatorData }) => ({
        ...state,
        operatorData,
        isFetchingOperator: false,
      }),
    },
    {
      name: "getOperadoresFailure",
      function: (state) => ({
        ...state,
        isFetchingOperator: false,
      }),
    },
  ],
  {
    isLoggedIn: false,
    isFetchingUserInfos: true,
    userInfos: {},
    operatorData: [],
    isFetchingOperator: false,
  }
);

export { Creators, reducers, sagas };
