import { Box, Grid } from "@material-ui/core";
import React from "react";
import {
  FilterButtonContained,
  FilterButtonOutlined,
  InputDate,
} from "../../../components";
import { Texts } from "../../../config";
import { Close, TransferRight } from "../../../config/icons";
import Style from "../styles/EquationParametersStyle";

function Inputs({ isFetching }) {
  const [fromDate, setFromDate] = React.useState(null);

  const texts = Texts["pt-BR"].report.reportFilter;
  return (
    <Box display="flex" flexDirection="column">
      <Style.Label>{texts.period}</Style.Label>
      <Grid container spacing={2} direction="row">
        <Grid item xs={4}>
          <InputDate
            name="start"
            maxDate={new Date()}
            onChange={(value, value2) => setFromDate(value2)}
            placeholder={texts.from}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={4}>
          <InputDate
            name="end"
            minDate={fromDate}
            maxDate={new Date()}
            disabled={fromDate === null}
            placeholder={texts.to}
            alternativeColors={2}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={5}>
              <FilterButtonOutlined
                type="clearDefault"
                onClick={() => {}}
                startIcon={<Close />}
              >
                {texts.clear}
              </FilterButtonOutlined>
            </Grid>
            <Grid item xs={7}>
              <FilterButtonContained
                loading={isFetching}
                type="submit"
                name="button"
                disabled={isFetching}
                startIcon={<TransferRight />}
              >
                {texts.apply}
              </FilterButtonContained>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Inputs;
