const colors = {
  checkout: {
    main: "#985BD5",
    dark: "#451E6D",
    transparent: "#985BD5A8",
    actual: "#BF9AE5",
  },
  load: {
    main: "#F0166D",
    dark: "#720531",
    transparent: "#F0166DA8",
    actual: "#FF97C0",
  },
  separation: {
    main: "#05D3D3",
    dark: "#346764",
    transparent: "#05D3D3A8",
    actual: "#C1F1F1",
  },
  globalDoughnut: {
    primary: "#5AB6E5",
    secundary: "#274A5B",
    textColor: "#FFFFFFE0",
    dots: "#B288F0",
    red: "#F0166D",
    yellow: "#FFD11A",
    green: "#05D3D3",
    bg: "#33374C",
  },
  commonLineChart: {
    gridColor: "#BFBFBF",
    labelsColor: "#FFFFFF",
    referenceLine: "#FFD11A",
    averageLine: "#05D3D3",
    backgroundLines: "#B288F0",
  },
  valueIndicative: {
    labelInside: "#FFFFFF",
    border: "#B288F0",
    background: "#656288",
  },
  historicProductivity: {
    referenceLine: "#FFD11A",
    averageLine: "#05D3D3",
    productivityLine: "#F0166D",
    productivityDot: "#F0166D",
    productivityDotBorder: "#FF7EB2",
    valueIndicative: "#FFFFFF",
    valueIndicativeBackground: "#19729F",
    valueIndicativeBorder: "#5AB6E5",
    gridColor: "#BFBFBF",
    labelsColor: "#FFFFFF",
  },
  processProductivity: {
    lines: "#B288F0",
    grid: "#6A6A6A66",
    checkoutIdeal: "#BCE0FFBF",
    checkoutActual: "#6AB9FFA8",
    checkoutActualBorder: "#6AB9FF",
    separationIdeal: "#BDF1EEBF",
    separationActual: "#05D3D3A8",
    separationActualBorder: "#05D3D3",
    loadIdeal: "#FFA7CABF",
    loadActual: "#F0166DA8",
    loadActualBorder: "#F0166D",
    movementIdeal: "#BF9AE5BF",
    movementActual: "#985BD5A8",
    movementActualBorder: "#985BD5",
    label: "#FFFFFFE0",
    textColor: "#FFFFFF"
  },
  orderingProductivity: {
    backgroundColor1: "#135D82",
    backgroundColor2: "#2D6F91",
    chartFill: "#5AB6E5",
    chartBackground: "#A4E0FF",
    positive: "#05D3D3",
    negative: "#F0166D",
    neutral: "#FFD11A",
    bg: "#33374C",
  },
  processCapacity: {
    checkout: {
      doughnutPrimary: "#985BD5",
      doughnutSecundary: "#451E6D",
      ideal: "#985BD5A8",
      idealBorder: "#985BD5",
      actual: "#BF9AE5",
    },
    load: {
      doughnutPrimary: "#F0166D",
      doughnutSecundary: "#720531",
      ideal: "#F0166DA8",
      idealBorder: "#F0166D",
      actual: "#FF97C0",
    },
    separation: {
      doughnutPrimary: "#05D3D3",
      doughnutSecundary: "#346764",
      ideal: "#05D3D3A8",
      idealBorder: "#05D3D3",
      actual: "#C1F1F1",
    },
    lines: "#B288F0",
    grid: "#6A6A6A66",
    label: "#FFFFFFE0",
    labelInside: "#FFFFFF",
    valueIndicativeBorder: "#B288F0",
    valueIndicativeBackground: "#656288",
  },
  periodResult: {
    valueByWeight: "#F34C4C",
    weightByFteByDay: "#6CCC6C",
    graph: {
      bottomColor: "#05D3D3A8",
      bottomBorder: "#05D3D3",
      topColor: "#F0166DA8",
      topBorder: "#F0166D",
    },
  },
  costsDriversProcess: {
    dimensions: {
      yellow: "#FFD11A",
      orange: "#EF7D00",
      blue: "#05D3D3",
      red: "#F0166D",
    },
  },
  opportunities: {
    unassignedHours: {
      yellowLine: "#FFD11A",
      redLine: "#F0166D",
      cyanLine: "#05D3D3",
      gridColor: "#BFBFBF",
      labelsColor: "#FFFFFF",
    },
    dimensionsResult: {
      gridColor: "#BFBFBF",
      labelsColor: "#FFFFFF",
      0: "#05D3D3A8",
      1: "#F0166DA8",
      2: "#FFD11AA8",
    },
  },
};

export default colors;
