import EquationParameters from "./containers/EquationParameters";
import EquationTable from "./containers/EquationTable";
import { reducers, sagas } from "./reduxSagas";

const Equation = {
  EquationParameters,
  EquationTable,
};

export { reducers, sagas, Equation };
