import React from "react";
import { ChartsColors } from "../../config";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import SkeletonLoader from "./SkeletonLoader";

const colors = ChartsColors.orderingProductivity;

const Table = styled.table({
  width: "100%",
  borderCollapse: "collapse",
});

const HeaderRow = styled.tr(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.medium14,
    textTransform: "uppercase",
    padding: spacing(2),
    textAlign: "left",
    position: "sticky",
    top: 0,
    backgroundColor: colors.bg,
  };
});

const GridData = styled.tr(({ $isOdd }) => {
  return {
    backgroundColor: $isOdd ? colors.backgroundColor1 : colors.backgroundColor2,
  };
});

const HeaderItem = styled.th(({ theme }) => {
  const { spacing } = theme;
  return {
    fontWeight: "normal",
    padding: spacing(1),
    whiteSpace: "normal",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  };
});

const ColumnItem = styled.td(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.roman12,
    color: colors.text.primary + "E0",
    padding: spacing(1),
  };
});

const ItemContainer = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
}));

function TableComponent({
  data,
  placeholderSize = 0,
  columnSize = 0,
  renderItemColumns,
  headers,
  loading,
  id,
}) {
  const placeholderList = createArray(placeholderSize);
  const columnList = createArray(columnSize);
  return (
    <Table id={id}>
      <HeaderRow>
        {headers.map((text, key) => (
          <HeaderItem key={key}>{text}</HeaderItem>
        ))}
      </HeaderRow>
      <tbody>
        {data || !loading
          ? data.map((item, index) => (
              <GridData $isOdd={(index + 1) % 2 === 1} key={index}>
                {renderItemColumns(item).map((itemToRender, key) => (
                  <ColumnItem key={key}>
                    <ItemContainer>{itemToRender}</ItemContainer>
                  </ColumnItem>
                ))}
              </GridData>
            ))
          : placeholderList.map((keyI) => (
              <GridData $isOdd={(keyI + 1) % 2 === 1} key={keyI}>
                {columnList.map((KeyJ) => (
                  <ColumnItem key={KeyJ}>
                    <ItemContainer>
                      <SkeletonLoader width="100%">&nbsp;</SkeletonLoader>
                    </ItemContainer>
                  </ColumnItem>
                ))}
              </GridData>
            ))}
      </tbody>
    </Table>
  );
}

export default React.memo(TableComponent);

function createArray(size) {
  const newArray = new Array(size);
  let i = 0;
  const iMax = size;
  for (; i < iMax; i++) {
    newArray[i] = i;
  }
  return newArray;
}
