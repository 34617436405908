import Home from "./Home";
import Login from "./Login";
import HomePreLogin from "./HomePreLogin";
import ForgotPassword from "./ForgotPassword";
import Productivity from "./Productivity";
import Capacity from "./Capacity";
import Costs from "./Costs";
import Opportunity from "./Opportunity";
import ChangePassword from "./ChangePassword";
import UserRegister from "./UserRegister";
import UserList from "./UserList";
import SetupEquation from "./SetupEquation";
import Report from "./Report";
import HomePage from "./HomePage";

const pages = {
  Home,
  Login,
  ForgotPassword,
  Productivity,
  Capacity,
  Costs,
  Opportunity,
  ChangePassword,
  UserRegister,
  UserList,
  HomePreLogin,
  SetupEquation,
  Report,
  HomePage
};
export default pages;
