import React from "react";
import Styles from "../styles/Styles";

import { FormHolder } from "../../../FormConfig";
import { Input, InputEmail } from "../../../components";
import { Spacing } from "../../../config";
import { Grid } from "@material-ui/core";

function Contact({ headerH, sendData, loading }) {
  return (
    <Styles.Contact minHeight $width={headerH}>
      <div
        style={{ gap: Spacing(1), display: "flex", flexDirection: "column" }}
      >
        <Styles.Title>CONTATO</Styles.Title>
        <Styles.SubTitle>
          Estamos ansiosos para ajudar a sua empresa a otimizar sua operação
          logística.
        </Styles.SubTitle>
        <Styles.ExtraTitle>
          Entre em contato conosco para obter mais informações sobre nossa
          solução de Labor Management System (LMS) e descubra como podemos
          colaborar para o sucesso do seu negócio.
        </Styles.ExtraTitle>
      </div>
      <Styles.ContactForm>
        <div
          style={{
            flex: 1,
            display: "flex",
            gap: "6px",
            flexDirection: "column",
          }}
        >
          <FormHolder onSubmit={sendData} clearOnSubmit={true}>
            <Grid
              container
              spacing={1}
              style={{ flexWrap: "nowrap", flexDirection: "column" }}
            >
              <Grid item spacing={1} container md={12}>
                <Grid item md={12} lg={6} style={{ width: "100%" }}>
                  <Input
                    name="name"
                    nameId="name"
                    required="*Campo Obrigatório"
                    margin={"dense"}
                    inputLabel="Nome"
                    placeholder={"Nome"}
                  />
                </Grid>
                <Grid item md={12} lg={6} style={{ width: "100%" }}>
                  <InputEmail
                    name="email"
                    nameId="email"
                    required="*Campo Obrigatório"
                    margin={"dense"}
                    inputLabel="Email"
                    placeholder={"Email"}
                  />
                </Grid>
              </Grid>

              <Grid item md={12}>
                <Input
                  name="obs"
                  nameId="obs"
                  required="*Campo Obrigatório"
                  inputLabel="COMENTÁRIOS ADICIONAIS"
                  multiline
                  rows={6}
                  margin={"dense"}
                  placeholder={"Comente sua necessidade ou observações"}
                />
              </Grid>
            </Grid>
            {/* <CustomText fontFamily={Fonts.roman}>
            Ao informar seus dados, você está ciente das diretrizes da{"  "}
            <Box
              onClick={() => console.log("B")}
              component="span"
              style={{
                fontFamily: Fonts.bold,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Política de Privacidade.
            </Box>
          </CustomText> */}
            <Styles.ContainedFormButton
              loading={loading}
              name="connect"
              type="submit"
              fullWidth={false}
            >
              Enviar
            </Styles.ContainedFormButton>
          </FormHolder>
        </div>
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            alignItems: "center",

            justifyContent: "center",
          }}
        >
          <Styles.MailSVG />
        </div>
      </Styles.ContactForm>
    </Styles.Contact>
  );
}

export default Contact;
