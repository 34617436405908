import Texts from "./texts";
import {
  AccountCash,
  AccountGroup,
  ArrowLeftRightBold,
  BriefCase,
  ChartArea,
  ChartBubble,
  DatabaseSearch,
  FileDocument,
  HammerWrench,
} from "./icons";
import { paths } from "../navigation/navigate";

const texts = Texts["pt-BR"].pagesConfig;

const pagesConfig = {
  notLogged: [
    {
      path: paths.homePage,
      name: "HomePage",
    },
    {
      path: paths.homePreLogin,
      name: "HomePreLogin",
    },
    {
      path: paths.login,
      name: "Login",
    },
    {
      path: paths.forgotPassword,
      name: "ForgotPassword",
    },
  ],
  logged: [
    {
      pages: [
        {
          path: paths.homePage,
          name: "HomePage",
        },
        {
          navigationId: 0,
          path: paths.home,
          name: "Home",
        },
        {
          navigationId: 0,
          path: paths.changePassword,
          name: "ChangePassword",
        },
        {
          navigationId: 0,
          path: paths.userRegister,
          name: "UserRegister",
        },
        {
          navigationId: 0,
          path: paths.userList,
          name: "UserList",
        },
      ],
    },
    {
      groupTitle: texts.dashboard.title,
      icon: ChartArea,
      pages: [
        {
          navigationId: 0,
          path: paths.productivity,
          icon: ChartArea,
          title: texts.dashboard.pages.productivity,
          name: "Productivity",
          withFilter: true,
          working: true,
          showOnHeader: true,
        },
        {
          navigationId: 0,
          path: paths.capacity,
          icon: ArrowLeftRightBold,
          title: texts.dashboard.pages.capacity,
          name: "Capacity",
          withFilter: true,
          working: true,
          showOnHeader: true,
        },
        {
          navigationId: 0,
          path: paths.costs,
          icon: AccountCash,
          title: texts.dashboard.pages.cost,
          name: "Costs",
          withFilter: true,
          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.opportunity,
          icon: BriefCase,
          title: texts.dashboard.pages.opportunity,
          name: "Opportunity",
          withFilter: true,
          working: true,
          showOnHeader: true,
        },
      ],
    },
    {
      groupTitle: texts.reports.title,
      icon: FileDocument,
      navigationId: 0,
      path: paths.report,
      title: texts.reports.title,
      name: "Report",
      working: true,
    },
    {
      groupTitle: texts.setup.title,
      icon: ArrowLeftRightBold,
      dontShow: false,
      pages: [
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.upload,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.dimensions,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.process,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.drivers,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.segment,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.skus,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.operators,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.schedules,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.layout,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.alocations,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.costs,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: paths.setupEquation,
          title: texts.setup.pages.equations,
          name: "SetupEquation",
          withFilter: false,
          working: true,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.integration,
          name: "",
          dontShow: true,
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.setup.pages.scenario,
          name: "",
          dontShow: true,
          working: false,
        },
      ],
    },
    {
      groupTitle: texts.model.title,
      icon: HammerWrench,
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.upload,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.builder,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.process,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.subProcess,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.actives,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.standardTimes,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.processBlock,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.conditional,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.model.pages.allocations,
          name: "",
          working: false,
        },
      ],
    },
    {
      groupTitle: texts.data.title,
      icon: ChartBubble,
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.upload,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.extract,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.transform,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.load,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.allocations,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.functions,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.conditional,
          name: "",
          working: false,
        },
        {
          navigationId: 0,
          path: "TODO",
          title: texts.data.pages.links,
          name: "",
          working: false,
        },
      ],
    },
    {
      groupTitle: texts.process.title,
      navigationId: 0,
      dontShow: true,
      icon: DatabaseSearch,
      path: "TODO",
      title: texts.process.title,
      name: "",
      working: false,
    },

    {
      dontShow: true,
      groupTitle: texts.coaching.title,
      icon: AccountGroup,
      navigationId: 0,
      path: "TODO",
      title: texts.coaching.title,
      name: "",
      working: false,
    },
  ],
};
export default pagesConfig;
