import styled from "styled-components";
import { Fonts, Logos } from "../../../../config";
import { Paper } from "@material-ui/core";
import PexelBg from "../../../../assets/images/img-02.png";

const Container = styled(Paper)((props) => ({
  display: "flex",
  width: "100%",
  height: "100%",
  position: "absolute",
  flexDirection: "row",
  background: "#0D518E",
  overflow: "hidden",
}));

const Logo = styled(Logos.LogoWhite)((props) => ({
  width: "172px",
  opacity: 1,
}));

const Left = styled.div((props) => ({
  display: "flex",
  minWidth: props.state.mdUp ? 725 : null,
  maxWidth: props.state.mdUp ? "43%" : "100%",
  marginRight: "auto",
  paddingTop: "4%",
  paddingLeft: "5%",
  paddingRight: "7%",
  paddingBottom: "4%",
  flexDirection: "column",
  overflowY: "scroll",
  overflowX: "hidden",
  background:
    "transparent linear-gradient(90deg, #00000080 0%, #00000026 100%) 0% 0% no-repeat padding-box;",
}));
const Right = styled.div((props) => ({
  display: "flex",
  marginLeft: "auto",
  backgroundImage: `url(${PexelBg})`,
  backgroundPosition: "revert",
  backgroundSize: "100%",
  width: "100%",
}));

const Title = styled.p((props) => ({
  fontFamily: Fonts.bold,
  fontSize: "large",
  color: "#5AB6E5",
  margin: 0,
}));

const SubTitle = styled.p((props) => ({
  color: "#fff",
  fontFamily: Fonts.roman,
  fontSize: "smaller",
  margin: 0,
}));
const Styles = {
  Container,
  Right,
  Left,
  Logo,
  Title,
  SubTitle,
};

export default Styles;
