import { create } from "apisauce";
import caller from "./helpers/caller";

import { SessionStorage } from "../lib";
import Models from "./models";
import Constants from "../config/constants";

const apiUrl = Constants.HML ?
  "https://deepen.ianclive.com"
  : "https://laboranalytics.com.br";

const urls = {
  COJ: `${apiUrl}/api_labor`,
  RCO: `${apiUrl}/api_labor_rio_claro`,
  EAA: `${apiUrl}/api_labor_escada`,
  FPP: `${apiUrl}/api_labor_castro`,
};

const api = create({
  baseURL: getUrl(localStorage.getItem("cdValue")),
  timeout: 200000,
});

function getUrl(value) {
  return urls[value] !== undefined ? urls[value] : urls["COJ"];
}

async function changeApi(value) {
  let newUrl = getUrl(value);
  api.setBaseURL(newUrl);
}

api.addRequestTransform((request) => {
  const token = SessionStorage.getItem("token");
  if (token) {
    request.headers.authorization = token;
  }
});

api.addMonitor((response) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return Models.getToken()
}

async function getUserInfos() {
  return Models.getUserInfos()
}

async function sendRecoveryPasswordRequest(email) {
  return caller(
    api.post,
    `/access/password_reset_request?email=${Models.sendPasswordRequest(email)}`
  );
}
async function sendRecoveryPasswordNewPassword(password) {
  return caller(
    api.post,
    "/access/password_reset_confirmation",
    Models.sendNewPassword,
    password
  );
}
async function recoveryPasswordCode(code) {
  return caller(
    api.post,
    "/access/password_reset_code",
    Models.sendPasswordCode,
    code
  );
}
async function changePassword(data) {
  return caller(api.post, "/user/change_password", null, data);
}
async function createAccess(data) {
  return caller(
    api.post,
    "/user/insert",
    null,
    Models.sendCreateAccessModel(data)
  );
}
async function getListaAcessos() {
  return caller(api.get, "/user/get_all", Models.getListaAcessos);
}
async function updateUserAccess(data) {
  return caller(
    api.post,
    "/user/update",
    null,
    Models.sendUpdateUserAccessModel(data)
  );
}

async function getOperadores() {
  return caller(api.get, "/operator/get_all", Models.getOperadores);
}

// TODO/HINT - Produtividade =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getGlobalProductivityChart(filters, cancelToken) {
 return Models.getGlobalProductivityChart();
}

// TODO/HINT - Capacidade =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getGlobalCapacityChart(filters) {
  const data = require("./mocks/getGlobalCapacityChart.json");
  return Models.getGlobalCapacityChart(data)
}
async function getCapacityIdleOrdering(filters) {
  const data = require("./mocks/getCapacityIdleOrdering.json");
return    Models.getCapacityIdleOrdering(data)
}

async function getCapacityProcessCharts(filters) {
const checkout = require("./mocks/getCapacityCheckoutProcessCharts.json");
const conferente = require("./mocks/getCapacityMovementProcessCharts.json")
const separador = require("./mocks/getCapacitySeparationProcessCharts.json")
  return Models.getCapacityProcessCharts({data: {checkout, conferente, separador}})
}

async function getCapacityHistoricChart(filters) {
  const responseChe = require("./mocks/getCapacityHistoricCheckoutChart.json");
  const responseSep = require("./mocks/getCapacityHistoricSeparationChart.json");
  const responseLoa = require("./mocks/getCapacityHistoricLoadChart.json");
  return Models.getCapacityHistoricCharts({data: {checkout: responseChe.data, separator: responseSep.data,shipper: responseLoa.data } })
}

// TODO/HINT - Custos =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getCostsServingList() {
  const response = require("./mocks/getCostsServingList.json");
  return Models.getCostsServingList(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}
async function getCostsIdleProcessCharts() {
  const response = require("./mocks/getCostsIdleProcessCharts.json");
  return Models.getCostsIdleProcessCharts(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getCostsPeriodResult() {
  const response = require("./mocks/getCostsPeriodResult.json");
  return Models.getCostsPeriodResult(response);
}

async function getCostsPeriodResultBarChart() {
  const response = require("./mocks/getCostsPeriodResultBarChart.json");
  return Models.getCostsPeriodResultBarChart(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getCostsDimensionsChart() {
  const response = require("./mocks/getCostsDimensionsChart.json");
  return Models.getCostsDimensionsChart(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getCostsDimensionsTable() {
  const response = require("./mocks/getCostsDimensionsTable.json");
  return Models.getCostsDimensionsTable(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getCostsProcessChart() {
  const response = require("./mocks/getCostsProcessChart.json");
  return Models.getCostsProcessChart(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getCostsProcessTable() {
  const response = require("./mocks/getCostsProcessTable.json");
  return Models.getCostsProcessTable(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

// TODO/HINT - Oportunidade =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getOpportunitiesCapacitySummary() {
  const response = require("./mocks/getOpportunitiesCapacitySummary.json");
  return Models.getOpportunitiesCapacitySummary(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getOpportunitiesProductivitySummary() {
  const response = require("./mocks/getOpportunitiesProductivitySummary.json");
  return Models.getOpportunitiesProductivitySummary(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getOpportunitiesSummary() {
  const response = require("./mocks/getOpportunitiesSummary.json");
  return Models.getOpportunitiesSummary(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getOpportunitiesDimensionsResultChart() {
  const response = require("./mocks/getOpportunitiesDimensionsResultChart.json");
  return Models.getOpportunitiesDimensionsResultChart(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getOpportunitiesDimensionsResultTable() {
  const response = require("./mocks/getOpportunitiesDimensionsResultTable.json");
  return Models.getOpportunitiesDimensionsResultTable(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

async function getOpportunitiesUnassignedHours() {
  const response = require("./mocks/getOpportunitiesUnassignedHours.json");
  return Models.getOpportunitiesUnassignedHours(response);
  // TODO/HINT
  /*
   * TODO remoção de mock e integração
   * return caller(api.post, "/login", Models.login, loginData);
   */
}

// TODO/HINT - Setup/Equações =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getEquationsSetup() {
  return caller(api.get, `/builder/get_all`, Models.getEquationsSetup);
}
async function sendEquationsSetup(data) {
  return caller(api.post, "/builder/insert", null, data);
}

// TODO/HINT - Setup/Equações =-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=

async function getReports(startDate, endDate) {
  return caller(
    api.get,
    `/shipping_order/all_ots?shippingStartDate=${startDate}&shippingEndDate=${endDate}`,
    Models.getReportList
  );
}

const apiServices = {
  changeApi,
  getToken,
  getUserInfos,
  sendRecoveryPasswordRequest,
  sendRecoveryPasswordNewPassword,
  recoveryPasswordCode,
  changePassword,
  createAccess,
  getListaAcessos,
  updateUserAccess,
  getOperadores,

  // Productivity
  getGlobalProductivityChart,

  // Capacity
  getGlobalCapacityChart,
  getCapacityIdleOrdering,
  getCapacityProcessCharts,
  getCapacityHistoricChart,

  // Costs
  getCostsServingList,
  getCostsIdleProcessCharts,
  getCostsPeriodResult,
  getCostsPeriodResultBarChart,
  getCostsDimensionsChart,
  getCostsDimensionsTable,
  getCostsProcessChart,
  getCostsProcessTable,

  // Opportunities
  getOpportunitiesSummary,
  getOpportunitiesCapacitySummary,
  getOpportunitiesDimensionsResultChart,
  getOpportunitiesDimensionsResultTable,
  getOpportunitiesProductivitySummary,
  getOpportunitiesUnassignedHours,

  // Setup/Equações
  getEquationsSetup,
  sendEquationsSetup,

  // Relatorio
  getReports,
};

export default apiServices;
